@import"https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&amp;display=swap";

[class^=border-].border-light, [class*=" border-"].border-light {
    border-color: rgba(255, 255, 255, .15) !important
}

.opacity-transition {
    transition: opacity .25s ease-in-out
}

.opacity-transition:hover {
    opacity: 1 !important
}

.hover-img-scale img {
    will-change: transform;
    transition: transform .3s ease-in-out;
    transform: none
}

.hover-img-scale:hover img {
    transform: translateY(-0.25rem) scale(1.01)
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #9691a4;
    --bs-gray-dark: #454056;
    --bs-gray-100: #f9f8fa;
    --bs-gray-200: #f5f4f8;
    --bs-gray-300: #efecf3;
    --bs-gray-400: #d5d2dc;
    --bs-gray-500: #bbb7c5;
    --bs-gray-600: #9691a4;
    --bs-gray-700: #666276;
    --bs-gray-800: #454056;
    --bs-gray-900: #1f1b2d;
    --bs-primary: #3b82f6;
    --bs-accent: #5d3cf2;
    --bs-secondary: #f5f4f8;
    --bs-success: #07c98b;
    --bs-info: #3c76f2;
    --bs-warning: #fdbc31;
    --bs-danger: #f23c49;
    --bs-light: #fff;
    --bs-dark: #1f1b2d;
    --bs-primary-rgb: 59, 130, 246;
    --bs-accent-rgb: 93, 60, 242;
    --bs-secondary-rgb: 245, 244, 248;
    --bs-success-rgb: 7, 201, 139;
    --bs-info-rgb: 60, 118, 242;
    --bs-warning-rgb: 253, 188, 49;
    --bs-danger-rgb: 242, 60, 73;
    --bs-light-rgb: 255, 255, 255;
    --bs-dark-rgb: 31, 27, 45;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 102, 98, 118;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: "Noto Sans", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(26.21deg, #5d3cf2 0%, #3b82f6 108.88%);
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #666276;
    --bs-body-bg: #fff;
}

*, *::before, *::after {
    box-sizing: border-box
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

hr {
    margin: 0 0;
    color: #efecf3;
    background-color: currentColor;
    border: 0;
    opacity: 1
}

hr:not([size]) {
    height: 1px
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.2;
    color: #1f1b2d
}

h1, .h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media(min-width: 1200px) {
    h1, .h1 {
        font-size: 2.5rem
    }
}

h2, .h2 {
    font-size: calc(1.325rem + 0.9vw)
}

@media(min-width: 1200px) {
    h2, .h2 {
        font-size: 2rem
    }
}

h3, .h3 {
    font-size: calc(1.3rem + 0.6vw)
}

@media(min-width: 1200px) {
    h3, .h3 {
        font-size: 1.75rem
    }
}

h4, .h4 {
    font-size: calc(1.275rem + 0.3vw)
}

@media(min-width: 1200px) {
    h4, .h4 {
        font-size: 1.5rem
    }
}

h5, .h5 {
    font-size: 1.25rem
}

h6, .h6 {
    font-size: 1.125rem
}

p {
    margin-top: 0;
    margin-bottom: 1.25rem
}

abbr[title], abbr[data-bs-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol, ul {
    padding-left: 2rem
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol, ul ul, ol ul, ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b, strong {
    font-weight: bolder
}

small, .small {
    font-size: 0.875em
}

mark, .mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub, sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

a {
    color: #3b82f6;
    text-decoration: underline
}

a:hover {
    color: #fd3509;
    text-decoration: none
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

pre, code, kbd, samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr
        /* rtl:ignore */
    ;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
    color: #fff
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: 0.875em;
    color: #e3116c;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #1f1b2d;
    border-radius: .375rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img, svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #9691a4;
    text-align: left
}

th {
    font-weight: 700;
    text-align: inherit;
    text-align: -webkit-match-parent
}

thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit
}

@media(min-width: 1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit
}

::file-selector-button {
    font: inherit
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: normal
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 700;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 700;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 700;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 700;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 700;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.3875rem + 1.65vw);
    font-weight: 700;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-6 {
        font-size: 2.625rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 0.875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1.25rem;
    font-size: 1.125rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1rem;
    color: #1f1b2d
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .375rem;
    background-color: #fff;
    border: 1px solid #efecf3;
    border-radius: .75rem;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 0.875em;
    color: #9691a4
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 500px) {
    .container-sm, .container {
        max-width: 100%
    }
}

@media(min-width: 768px) {
    .container-md, .container-sm, .container {
        max-width: 100%
    }
}

@media(min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
        max-width: 100%
    }
}

@media(min-width: 992px) {
    .account{
        display: none !important;
     
    }
}

@media(min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 100%
    }
}

@media(min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--bs-gutter-y));
    margin-right: calc(-0.5*var(--bs-gutter-x));
    margin-left: calc(-0.5*var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-top: var(--bs-gutter-y)
}
.slick-arrow{

    display: none !important;
}

.slider3 .slick-arrow{

    display: block !important;
    transition: opacity .25s ease-in-out;
opacity: 0;
margin-top: 2px!important;
}







.slider3 .slick-list{
height: 200px !important;
}

.slider3 .slick-track{
    height: 100% !important;
    }

    .categoryslider .slick-track{
        display: flex;
    flex-wrap: wrap;
    flex-direction: column;
   
flex-direction: row;
        }
    
    

    .slider3 .slick-slide {
        height: 100% !important;
        }
        .slider3 .slick-slide div {
            height: 200px !important;
            }

            .slider3 .slick-slide div img {
                height: 100% !important;
                }
/* Custom arrow styling */
.slider3 .slick-prev, .slider3 .slick-next {
    z-index: 100;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translate(0, -50%);
    background-size: contain;
    border: none;
    cursor: pointer;
 
}

.slider3 .slick-prev {
    left: 10px;
}

.slider3 .slick-next {
    right: 10px;
}

/* Hide default arrows */
.slider3 .slick-prev:before, .slider3 .slick-next:before {
    display: none;
}

.slider2 .slick-slide{
    opacity: 0.5;
}

@media (max-width: 767.98px) {
    
    .slider2 .slick-slide{
        opacity: 1;
    }
}


.slider2 .slick-center{
    opacity: 1;
}



.slider2 .slick-active{
    opacity: 1;
}


.col {
    flex: 1 0 0%
}



.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0, .gx-0 {
    --bs-gutter-x: 0
}

.g-0, .gy-0 {
    --bs-gutter-y: 0
}

.g-1, .gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1, .gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2, .gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2, .gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3, .gx-3 {
    --bs-gutter-x: 1rem
}

.g-3, .gy-3 {
    --bs-gutter-y: 1rem
}

.g-4, .gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4, .gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5, .gx-5 {
    --bs-gutter-x: 3rem
}

.g-5, .gy-5 {
    --bs-gutter-y: 3rem
}

@media(min-width: 500px) {
    .col-sm {
        flex: 1 0 0%
    }

        .w-sm-70 {
          width: 70% !important;
        }
      
      

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0, .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0, .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1, .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1, .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2, .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2, .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3, .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3, .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4, .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4, .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5, .gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5, .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0, .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0, .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1, .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1, .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2, .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2, .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3, .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3, .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4, .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4, .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5, .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5, .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0, .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0, .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1, .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1, .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2, .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2, .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3, .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3, .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4, .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4, .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5, .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5, .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0, .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0, .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1, .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1, .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2, .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2, .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3, .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3, .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4, .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4, .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5, .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5, .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0, .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0, .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1, .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1, .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2, .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2, .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3, .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3, .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4, .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4, .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5, .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5, .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #666276;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.0375);
    --bs-table-active-color: #666276;
    --bs-table-active-bg: rgba(0, 0, 0, 0.05);
    --bs-table-hover-color: #666276;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.05);
    width: 100%;
    margin-bottom: 1rem;
    color: #666276;
    vertical-align: top;
    border-color: #efecf3
}

.table>:not(caption)>*>* {
    padding: .75rem .75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:first-child) {
    border-top: 2px solid currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .375rem .375rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #ffeeea;
    --bs-table-striped-bg: #f5e5e1;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #f2e2de;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #f2e2de;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6d6d3
}

.table-accent {
    --bs-table-bg: #efecfe;
    --bs-table-striped-bg: #e6e3f4;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e3e0f1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e3e0f1;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #d7d4e5
}

.table-secondary {
    --bs-table-bg: #f9f8fa;
    --bs-table-striped-bg: #f0eff1;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #edecee;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #edecee;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e0dfe1
}

.table-success {
    --bs-table-bg: #e6faf3;
    --bs-table-striped-bg: #ddf1ea;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dbeee7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #dbeee7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cfe1db
}

.table-info {
    --bs-table-bg: #ecf1fe;
    --bs-table-striped-bg: #e3e8f4;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e0e5f1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e0e5f1;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #d4d9e5
}

.table-warning {
    --bs-table-bg: #fff8ea;
    --bs-table-striped-bg: #f5efe1;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #f2ecde;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #f2ecde;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dfd3
}

.table-danger {
    --bs-table-bg: #feeced;
    --bs-table-striped-bg: #f4e3e4;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #f1e0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #f1e0e1;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e5d4d5
}

.table-dark {
    --bs-table-bg: #1f1b2d;
    --bs-table-striped-bg: #272435;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #2a2638;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #2a2638;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #353242
}

.table-light {
    --bs-table-bg: #fff;
    --bs-table-striped-bg: whitesmoke;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #f2f2f2;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #f2f2f2;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6e6e6
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media(max-width: 499.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .375rem;
    font-size: 1rem;
    color: #1f1b2d
}

.col-form-label {
    padding-top: calc(0.575rem + 1px);
    padding-bottom: calc(0.575rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    color: #1f1b2d
}

.col-form-label-lg {
    padding-top: calc(0.825rem + 1px);
    padding-bottom: calc(0.825rem + 1px);
    font-size: 1rem
}

.col-form-label-sm {
    padding-top: calc(0.425rem + 1px);
    padding-bottom: calc(0.425rem + 1px);
    font-size: 0.875rem
}

.form-text {
    margin-top: .25rem;
    font-size: 0.875em;
    color: #9691a4
}

.form-control {
    display: block;
    width: 100%;
    padding: .575rem 1.125rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #454056;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d5d2dc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .5rem;
    box-shadow: inset 0 1px 2px transparent;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #454056;
    background-color: #fff;
    border-color: #feab98;
    outline: 0;
    box-shadow: inset 0 1px 2px transparent, 0 0 0 .125rem rgba(253, 86, 49, .25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-moz-placeholder {
    color: #9691a4;
    opacity: 1
}

.form-control::placeholder {
    color: #9691a4;
    opacity: 1
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f5f4f8;
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .575rem 1.125rem;
    margin: -0.575rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
    color: #454056;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out
}

.form-control::file-selector-button {
    padding: .575rem 1.125rem;
    margin: -0.575rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
    color: #454056;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out
}

.form-control::file-selector-button {
    padding: .575rem 1.125rem;
    margin: -0.575rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
    color: #454056;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        transition: none
    }

    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2
}

.form-control::-webkit-file-upload-button {
    padding: .575rem 1.125rem;
    margin: -0.575rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
    color: #454056;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .575rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #666276;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + 0.85rem + 2px);
    padding: .425rem 1rem;
    font-size: 0.875rem;
    border-radius: .375rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .425rem 1rem;
    margin: -0.425rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-sm::file-selector-button {
    padding: .425rem 1rem;
    margin: -0.425rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-sm::file-selector-button {
    padding: .425rem 1rem;
    margin: -0.425rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .425rem 1rem;
    margin: -0.425rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: .825rem 1.25rem;
    font-size: 1rem;
    border-radius: .5rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .825rem 1.25rem;
    margin: -0.825rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem
}

.form-control-lg::file-selector-button {
    padding: .825rem 1.25rem;
    margin: -0.825rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem
}

.form-control-lg::file-selector-button {
    padding: .825rem 1.25rem;
    margin: -0.825rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .825rem 1.25rem;
    margin: -0.825rem -1.25rem;
    -webkit-margin-end: 1.25rem;
    margin-inline-end: 1.25rem
}

textarea.form-control {
    min-height: calc(1.5em + 1.15rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + 0.85rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: .575rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .5rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .5rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .575rem 2.53125rem .575rem 1.125rem;
    -moz-padding-start: calc(1.125rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #454056;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23454056' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1.125rem center;
    background-size: 16px 12px;
    border: 1px solid #d5d2dc;
    border-radius: .5rem;
    box-shadow: inset 0 1px 2px transparent;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #feab98;
    outline: 0;
    box-shadow: inset 0 1px 2px transparent, 0 0 0 .125rem rgba(253, 86, 49, .25)
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1.125rem;
    background-image: none
}

.form-select:disabled {
    background-color: #f5f4f8
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #454056
}

.form-select-sm {
    padding-top: .425rem;
    padding-bottom: .425rem;
    padding-left: 1rem;
    font-size: 0.875rem;
    border-radius: .375rem
}

.form-select-lg {
    padding-top: .825rem;
    padding-bottom: .825rem;
    padding-left: 1.25rem;
    font-size: 1rem;
    border-radius: .5rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .375rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid #bbb7c5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-check-input[type=checkbox] {
    border-radius: .1875em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: 0
}

.form-check-input:focus {
    border-color: #feab98;
    outline: 0;
    box-shadow: 0
}

.form-check-input:checked {
    background-color: transparent;
    border-color: #3b82f6
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fd5631' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fd5631'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #3b82f6;
    border-color: #3b82f6;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input[disabled]~.form-check-label, .form-check-input:disabled~.form-check-label {
    opacity: .5
}

.form-check-label {
    color: #666276
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check[disabled]+.btn, .btn-check:disabled+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.25rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .125rem rgba(253, 86, 49, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .125rem rgba(253, 86, 49, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3b82f6;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #fd3104
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #efecf3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px transparent
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3b82f6;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #fd3104
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #efecf3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px transparent
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #bbb7c5
}

.form-range:disabled::-moz-range-thumb {
    background-color: #bbb7c5
}

.form-floating {
    position: relative
}

.form-floating>.form-control, .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 1.125rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control {
    padding: 1rem 1.125rem
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus, .input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .575rem 1.125rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #666276;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d5d2dc;
    border-radius: .5rem
}

.input-group-lg>.form-control, .input-group-lg>.form-select, .input-group-lg>.input-group-text, .input-group-lg>.btn {
    padding: .825rem 1.25rem;
    font-size: 1rem;
    border-radius: .5rem
}

.input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text, .input-group-sm>.btn {
    padding: .425rem 1rem;
    font-size: 0.875rem;
    border-radius: .375rem
}

.input-group-lg>.form-select, .input-group-sm>.form-select {
    padding-right: 3.65625rem
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu), .input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 0.75rem;
    color: #07c98b
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: 0.75rem;
    color: #000;
    background-color: rgba(7, 201, 139, .9);
    border-radius: .375rem
}

.was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip, .is-valid~.valid-feedback, .is-valid~.valid-tooltip {
    display: block
}

.was-validated .form-control:valid, .form-control.is-valid {
    border-color: #07c98b;
    padding-right: calc(1.5em + 1.15rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3e%3cpath fill-rule='evenodd' d='M9 3a6 6 0 1 0 0 12A6 6 0 1 0 9 3zM1.5 9a7.5 7.5 0 1 1 15 0 7.5 7.5 0 1 1-15 0zm10.28-2.03a.75.75 0 0 1 0 1.061l-3 3a.75.75 0 0 1-1.061 0l-1.5-1.5A.75.75 0 0 1 7.28 8.47l.97.97 2.47-2.47a.75.75 0 0 1 1.061 0z' fill='%2307c98b' fill-opacity='.6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.2875rem) center;
    background-size: calc(0.75em + 0.575rem) calc(0.75em + 0.575rem)
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #07c98b;
    box-shadow: 0 0 0 .125rem rgba(7, 201, 139, .25)
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1.15rem);
    background-position: top calc(0.375em + 0.2875rem) right calc(0.375em + 0.2875rem)
}

.was-validated .form-select:valid, .form-select.is-valid {
    border-color: #07c98b
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 5.34375rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23454056' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3e%3cpath fill-rule='evenodd' d='M9 3a6 6 0 1 0 0 12A6 6 0 1 0 9 3zM1.5 9a7.5 7.5 0 1 1 15 0 7.5 7.5 0 1 1-15 0zm10.28-2.03a.75.75 0 0 1 0 1.061l-3 3a.75.75 0 0 1-1.061 0l-1.5-1.5A.75.75 0 0 1 7.28 8.47l.97.97 2.47-2.47a.75.75 0 0 1 1.061 0z' fill='%2307c98b' fill-opacity='.6'/%3e%3c/svg%3e");
    background-position: right 1.125rem center, center right 2.53125rem;
    background-size: 16px 12px, calc(0.75em + 0.575rem) calc(0.75em + 0.575rem)
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #07c98b;
    box-shadow: 0 0 0 .125rem rgba(7, 201, 139, .25)
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #07c98b
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #07c98b
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 .125rem rgba(7, 201, 139, .25)
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
    color: #07c98b
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated .input-group .form-select:valid, .input-group .form-select.is-valid {
    z-index: 1
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .input-group .form-select.is-valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 0.75rem;
    color: #f23c49
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: 0.75rem;
    color: #000;
    background-color: rgba(242, 60, 73, .9);
    border-radius: .375rem
}

.was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip, .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #f23c49;
    padding-right: calc(1.5em + 1.15rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23f23c49' fill-rule='evenodd'%3e%3cpath d='M9 3a6 6 0 1 0 0 12A6 6 0 1 0 9 3zM1.5 9a7.5 7.5 0 1 1 15 0 7.5 7.5 0 1 1-15 0zM9 5.25a.75.75 0 0 1 .75.75v3a.75.75 0 1 1-1.5 0V6A.75.75 0 0 1 9 5.25zM8.25 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 1 1 0 1.5H9a.75.75 0 0 1-.75-.75z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.2875rem) center;
    background-size: calc(0.75em + 0.575rem) calc(0.75em + 0.575rem)
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f23c49;
    box-shadow: 0 0 0 .125rem rgba(242, 60, 73, .25)
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1.15rem);
    background-position: top calc(0.375em + 0.2875rem) right calc(0.375em + 0.2875rem)
}

.was-validated .form-select:invalid, .form-select.is-invalid {
    border-color: #f23c49
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 5.34375rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23454056' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23f23c49' fill-rule='evenodd'%3e%3cpath d='M9 3a6 6 0 1 0 0 12A6 6 0 1 0 9 3zM1.5 9a7.5 7.5 0 1 1 15 0 7.5 7.5 0 1 1-15 0zM9 5.25a.75.75 0 0 1 .75.75v3a.75.75 0 1 1-1.5 0V6A.75.75 0 0 1 9 5.25zM8.25 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 1 1 0 1.5H9a.75.75 0 0 1-.75-.75z'/%3e%3c/svg%3e");
    background-position: right 1.125rem center, center right 2.53125rem;
    background-size: 16px 12px, calc(0.75em + 0.575rem) calc(0.75em + 0.575rem)
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #f23c49;
    box-shadow: 0 0 0 .125rem rgba(242, 60, 73, .25)
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #f23c49
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #f23c49
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 .125rem rgba(242, 60, 73, .25)
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
    color: #f23c49
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated .input-group .form-select:invalid, .input-group .form-select.is-invalid {
    z-index: 2
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .input-group .form-select.is-invalid:focus {
    z-index: 3
}

.btn {
    display: inline-block;
    font-weight: bold;
    line-height: 1.5;
    color: #666276;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .575rem 1.5rem;
    font-size: 1rem;
    border-radius: .5rem;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #666276
}

.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: unset
}

.btn-check:checked+.btn, .btn-check:active+.btn, .btn:active, .btn.active {
    box-shadow: unset
}

.btn-check:checked+.btn:focus, .btn-check:active+.btn:focus, .btn:active:focus, .btn.active:focus {
    box-shadow: unset, unset
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65;
    box-shadow: none
}

.btn-primary {
    color: #000;
    background-color: #3b82f6;
    border-color: #3b82f6;
    box-shadow: unset
}

.btn-primary:hover {
    color: #000;
    background-color: #fd6f50;
    border-color: #fd6746
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #000;
    background-color: #fd6f50;
    border-color: #fd6746;
    box-shadow: unset, 0 0 0 0 rgba(215, 73, 42, .5)
}

.btn-check:checked+.btn-primary, .btn-check:active+.btn-primary, .btn-primary:active, .btn-primary.active, .show>.btn-primary.dropdown-toggle {
    color: #000;
    background-color: #fd785a;
    border-color: #fd6746
}

.btn-check:checked+.btn-primary:focus, .btn-check:active+.btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(215, 73, 42, .5)
}

.btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #3b82f6;
    border-color: #3b82f6
}

.btn-accent {
    color: #fff;
    background-color: #5d3cf2;
    border-color: #5d3cf2;
    box-shadow: unset
}

.btn-accent:hover {
    color: #fff;
    background-color: #4f33ce;
    border-color: #4a30c2
}

.btn-check:focus+.btn-accent, .btn-accent:focus {
    color: #fff;
    background-color: #4f33ce;
    border-color: #4a30c2;
    box-shadow: unset, 0 0 0 0 rgba(117, 89, 244, .5)
}

.btn-check:checked+.btn-accent, .btn-check:active+.btn-accent, .btn-accent:active, .btn-accent.active, .show>.btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #4a30c2;
    border-color: #462db6
}

.btn-check:checked+.btn-accent:focus, .btn-check:active+.btn-accent:focus, .btn-accent:active:focus, .btn-accent.active:focus, .show>.btn-accent.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(117, 89, 244, .5)
}

.btn-accent:disabled, .btn-accent.disabled {
    color: #fff;
    background-color: #5d3cf2;
    border-color: #5d3cf2
}

.btn-secondary {
    color: #000;
    background-color: #f5f4f8;
    border-color: #f5f4f8;
    box-shadow: unset
}

.btn-secondary:hover {
    color: #000;
    background-color: #f7f6f9;
    border-color: #f6f5f9
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
    color: #000;
    background-color: #f7f6f9;
    border-color: #f6f5f9;
    box-shadow: unset, 0 0 0 0 rgba(208, 207, 211, .5)
}

.btn-check:checked+.btn-secondary, .btn-check:active+.btn-secondary, .btn-secondary:active, .btn-secondary.active, .show>.btn-secondary.dropdown-toggle {
    color: #000;
    background-color: #f7f6f9;
    border-color: #f6f5f9
}

.btn-check:checked+.btn-secondary:focus, .btn-check:active+.btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(208, 207, 211, .5)
}

.btn-secondary:disabled, .btn-secondary.disabled {
    color: #000;
    background-color: #f5f4f8;
    border-color: #f5f4f8
}

.btn-success {
    color: #000;
    background-color: #07c98b;
    border-color: #07c98b;
    box-shadow: unset
}

.btn-success:hover {
    color: #000;
    background-color: #2cd19c;
    border-color: #20ce97
}

.btn-check:focus+.btn-success, .btn-success:focus {
    color: #000;
    background-color: #2cd19c;
    border-color: #20ce97;
    box-shadow: unset, 0 0 0 0 rgba(6, 171, 118, .5)
}

.btn-check:checked+.btn-success, .btn-check:active+.btn-success, .btn-success:active, .btn-success.active, .show>.btn-success.dropdown-toggle {
    color: #000;
    background-color: #39d4a2;
    border-color: #20ce97
}

.btn-check:checked+.btn-success:focus, .btn-check:active+.btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show>.btn-success.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(6, 171, 118, .5)
}

.btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #07c98b;
    border-color: #07c98b
}

.btn-info {
    color: #000;
    background-color: #3c76f2;
    border-color: #3c76f2;
    box-shadow: unset
}

.btn-info:hover {
    color: #000;
    background-color: #598bf4;
    border-color: #5084f3
}

.btn-check:focus+.btn-info, .btn-info:focus {
    color: #000;
    background-color: #598bf4;
    border-color: #5084f3;
    box-shadow: unset, 0 0 0 0 rgba(51, 100, 206, .5)
}

.btn-check:checked+.btn-info, .btn-check:active+.btn-info, .btn-info:active, .btn-info.active, .show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #6391f5;
    border-color: #5084f3
}

.btn-check:checked+.btn-info:focus, .btn-check:active+.btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(51, 100, 206, .5)
}

.btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #3c76f2;
    border-color: #3c76f2
}

.btn-warning {
    color: #000;
    background-color: #fdbc31;
    border-color: #fdbc31;
    box-shadow: unset
}

.btn-warning:hover {
    color: #000;
    background-color: #fdc650;
    border-color: #fdc346
}

.btn-check:focus+.btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #fdc650;
    border-color: #fdc346;
    box-shadow: unset, 0 0 0 0 rgba(215, 160, 42, .5)
}

.btn-check:checked+.btn-warning, .btn-check:active+.btn-warning, .btn-warning:active, .btn-warning.active, .show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #fdc95a;
    border-color: #fdc346
}

.btn-check:checked+.btn-warning:focus, .btn-check:active+.btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show>.btn-warning.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(215, 160, 42, .5)
}

.btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #fdbc31;
    border-color: #fdbc31
}

.btn-danger {
    color: #000;
    background-color: #f23c49;
    border-color: #f23c49;
    box-shadow: unset
}

.btn-danger:hover {
    color: #000;
    background-color: #f45964;
    border-color: #f3505b
}

.btn-check:focus+.btn-danger, .btn-danger:focus {
    color: #000;
    background-color: #f45964;
    border-color: #f3505b;
    box-shadow: unset, 0 0 0 0 rgba(206, 51, 62, .5)
}

.btn-check:checked+.btn-danger, .btn-check:active+.btn-danger, .btn-danger:active, .btn-danger.active, .show>.btn-danger.dropdown-toggle {
    color: #000;
    background-color: #f5636d;
    border-color: #f3505b
}

.btn-check:checked+.btn-danger:focus, .btn-check:active+.btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show>.btn-danger.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(206, 51, 62, .5)
}

.btn-danger:disabled, .btn-danger.disabled {
    color: #000;
    background-color: #f23c49;
    border-color: #f23c49
}

.btn-light {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    box-shadow: unset
}

.btn-light:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-light, .btn-light:focus {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    box-shadow: unset, 0 0 0 0 rgba(217, 217, 217, .5)
}

.btn-check:checked+.btn-light, .btn-check:active+.btn-light, .btn-light:active, .btn-light.active, .show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:checked+.btn-light:focus, .btn-check:active+.btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show>.btn-light.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(217, 217, 217, .5)
}

.btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-dark {
    color: #fff;
    background-color: #1f1b2d;
    border-color: #1f1b2d;
    box-shadow: unset
}

.btn-dark:hover {
    color: #fff;
    background-color: #1a1726;
    border-color: #191624
}

.btn-check:focus+.btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1a1726;
    border-color: #191624;
    box-shadow: unset, 0 0 0 0 rgba(65, 61, 77, .5)
}

.btn-check:checked+.btn-dark, .btn-check:active+.btn-dark, .btn-dark:active, .btn-dark.active, .show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #191624;
    border-color: #171422
}

.btn-check:checked+.btn-dark:focus, .btn-check:active+.btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show>.btn-dark.dropdown-toggle:focus {
    box-shadow: unset, 0 0 0 0 rgba(65, 61, 77, .5)
}

.btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #1f1b2d;
    border-color: #1f1b2d
}

.btn-outline-primary {
    color: #3b82f6;
    border-color: #3b82f6
}

.btn-outline-primary:hover {
    color: #000;
    background-color: #3b82f6;
    border-color: #3b82f6
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(253, 86, 49, .5)
}

.btn-check:checked+.btn-outline-primary, .btn-check:active+.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #3b82f6;
    border-color: #3b82f6
}

.btn-check:checked+.btn-outline-primary:focus, .btn-check:active+.btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(253, 86, 49, .5)
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #3b82f6;
    background-color: transparent
}

.btn-outline-accent {
    color: #5d3cf2;
    border-color: #5d3cf2
}

.btn-outline-accent:hover {
    color: #fff;
    background-color: #5d3cf2;
    border-color: #5d3cf2
}

.btn-check:focus+.btn-outline-accent, .btn-outline-accent:focus {
    box-shadow: 0 0 0 0 rgba(93, 60, 242, .5)
}

.btn-check:checked+.btn-outline-accent, .btn-check:active+.btn-outline-accent, .btn-outline-accent:active, .btn-outline-accent.active, .btn-outline-accent.dropdown-toggle.show {
    color: #fff;
    background-color: #5d3cf2;
    border-color: #5d3cf2
}

.btn-check:checked+.btn-outline-accent:focus, .btn-check:active+.btn-outline-accent:focus, .btn-outline-accent:active:focus, .btn-outline-accent.active:focus, .btn-outline-accent.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(93, 60, 242, .5)
}

.btn-outline-accent:disabled, .btn-outline-accent.disabled {
    color: #5d3cf2;
    background-color: transparent
}

.btn-outline-secondary {
    color: #f5f4f8;
    border-color: #f5f4f8
}


.btn-outline-secondary:hover {
    color: #000;
    background-color: #f5f4f8;
    border-color: #f5f4f8
}

.btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgba(245, 244, 248, .5)
}

.btn-check:checked+.btn-outline-secondary, .btn-check:active+.btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #f5f4f8;
    background-color: #3b82f6;
    border-color: #f5f4f8
    
}

.btn-check:checked+.btn-outline-secondary:focus, .btn-check:active+.btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(245, 244, 248, .5)
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #f5f4f8;
    background-color: transparent
}

.btn-outline-success {
    color: #07c98b;
    border-color: #07c98b
}

.btn-outline-success:hover {
    color: #000;
    background-color: #07c98b;
    border-color: #07c98b
}

.btn-check:focus+.btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0 rgba(7, 201, 139, .5)
}

.btn-check:checked+.btn-outline-success, .btn-check:active+.btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #07c98b;
    border-color: #07c98b
}

.btn-check:checked+.btn-outline-success:focus, .btn-check:active+.btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(7, 201, 139, .5)
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #07c98b;
    background-color: transparent
}

.btn-outline-info {
    color: #3c76f2;
    border-color: #3c76f2
}

.btn-outline-info:hover {
    color: #000;
    background-color: #3c76f2;
    border-color: #3c76f2
}

.btn-check:focus+.btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0 rgba(60, 118, 242, .5)
}

.btn-check:checked+.btn-outline-info, .btn-check:active+.btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #3c76f2;
    border-color: #3c76f2
}

.btn-check:checked+.btn-outline-info:focus, .btn-check:active+.btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(60, 118, 242, .5)
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #3c76f2;
    background-color: transparent
}

.btn-outline-warning {
    color: #fdbc31;
    border-color: #fdbc31
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #fdbc31;
    border-color: #fdbc31
}

.btn-check:focus+.btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0 rgba(253, 188, 49, .5)
}

.btn-check:checked+.btn-outline-warning, .btn-check:active+.btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #fdbc31;
    border-color: #fdbc31
}

.btn-check:checked+.btn-outline-warning:focus, .btn-check:active+.btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(253, 188, 49, .5)
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #fdbc31;
    background-color: transparent
}

.btn-outline-danger {
    color: #f23c49;
    border-color: #f23c49
}

.btn-outline-danger:hover {
    color: #000;
    background-color: #f23c49;
    border-color: #f23c49
}

.btn-check:focus+.btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0 rgba(242, 60, 73, .5)
}

.btn-check:checked+.btn-outline-danger, .btn-check:active+.btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #f23c49;
    border-color: #f23c49
}

.btn-check:checked+.btn-outline-danger:focus, .btn-check:active+.btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(242, 60, 73, .5)
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #f23c49;
    background-color: transparent
}

.btn-outline-light {
    color: #fff;
    border-color: #fff
}

.btn-outline-light:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .5)
}

.btn-check:checked+.btn-outline-light, .btn-check:active+.btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:checked+.btn-outline-light:focus, .btn-check:active+.btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(255, 255, 255, .5)
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-dark {
    color: #1f1b2d;
    border-color: #1f1b2d
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #1f1b2d;
    border-color: #1f1b2d
}

.btn-check:focus+.btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0 rgba(31, 27, 45, .5)
}

.btn-check:checked+.btn-outline-dark, .btn-check:active+.btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #1f1b2d;
    border-color: #1f1b2d
}

.btn-check:checked+.btn-outline-dark:focus, .btn-check:active+.btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: unset, 0 0 0 0 rgba(31, 27, 45, .5)
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #1f1b2d;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #666276;
    text-decoration: underline
}

.btn-link:hover {
    color: #3b82f6;
    text-decoration: none
}

.btn-link:focus {
    text-decoration: none
}

.btn-link:disabled, .btn-link.disabled {
    color: #9691a4
}

.btn-lg, .input-group-lg .btn, .btn-group-lg>.btn {
    padding: .825rem 1.75rem;
    font-size: 1rem;
    border-radius: .5rem
}

.btn-sm, .input-group-sm .btn, .btn-group-sm>.btn {
    padding: .425rem 1rem;
    font-size: 0.875rem;
    border-radius: .375rem
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media(prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media(prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropup, .dropend, .dropdown, .dropstart {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 0.875rem;
    color: #666276;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #efecf3;
    border-radius: .75rem;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media(min-width: 500px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .375rem 0;
    overflow: hidden;
    border-top: 1px solid #efecf3
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .375rem 1rem;
    clear: both;
    font-weight: 400;
    color: #454056;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:hover, .dropdown-item:focus {
    color: #3b82f6;
    background-color: transparent
}

.dropdown-item.active, .dropdown-item:active {
    color: #3b82f6;
    text-decoration: none;
    background-color: transparent
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: #9691a4;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #1f1b2d;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .375rem 1rem;
    color: #454056
}

.dropdown-menu-dark {
    color: rgba(255, 255, 255, .65);
    background-color: #1f1b2d;
    border-color: rgba(255, 255, 255, .15);
    box-shadow: none
}

.dropdown-menu-dark .dropdown-item {
    color: rgba(255, 255, 255, .65)
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: transparent
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: transparent
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
    color: rgba(255, 255, 255, .4)
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: rgba(255, 255, 255, .65)
}

.dropdown-menu-dark .dropdown-header {
    color: #fff
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group>.btn, .btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn:hover, .btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active, .btn-group-vertical>.btn-check:checked+.btn, .btn-group-vertical>.btn-check:focus+.btn, .btn-group-vertical>.btn:hover, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child), .btn-group>.btn-group:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle), .btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn, .btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-sm+.dropdown-toggle-split, .input-group-sm .btn+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-lg+.dropdown-toggle-split, .input-group-lg .btn+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem
}

.btn-group.show .dropdown-toggle {
    box-shadow: unset
}

.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn, .btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn~.btn, .btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    font-weight: bold;
    color: #454056;
    text-decoration: none;
    transition: color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out, border-color .25s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:hover, .nav-link:focus {
    color: #3b82f6
}

.nav-link.disabled {
    color: #9691a4;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid transparent
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #9691a4;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #3b82f6;
    background-color: #fff;
    border-color: transparent
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 50rem
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #3b82f6;
    background-color: #fff
}

.nav-fill>.nav-link, .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified>.nav-link, .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-sm, .navbar>.container-md, .navbar>.container-lg, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: calc(1.275rem + 0.3vw);
    text-decoration: none;
    white-space: nowrap
}

@media(min-width: 1200px) {
    .navbar-brand {
        font-size: 1.5rem
    }
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    transition: box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media(min-width: 500px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-sm .offcanvas-top, .navbar-expand-sm .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-md .offcanvas-top, .navbar-expand-md .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-lg .offcanvas-top, .navbar-expand-lg .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-xl .offcanvas-top, .navbar-expand-xl .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-xxl .offcanvas-top, .navbar-expand-xxl .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none
}

.navbar-expand .offcanvas-top, .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0
}

.navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-light .navbar-brand {
    color: #1f1b2d
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #1f1b2d
}

.navbar-light .navbar-nav .nav-link {
    color: #454056
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #3b82f6
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: #9691a4
}

.navbar-light .navbar-nav .show>.nav-link, .navbar-light .navbar-nav .nav-link.active {
    color: #3b82f6
}

.navbar-light .navbar-toggler {
    color: #454056;
    border-color: transparent
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23454056' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: #454056
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: #3b82f6
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .65)
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .4)
}

.navbar-dark .navbar-nav .show>.nav-link, .navbar-dark .navbar-nav .nav-link.active {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .65);
    border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.65%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .65)
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efecf3;
    border-radius: .75rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px)
}

.card>.card-header+.list-group, .card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem
}

.card-title {
    margin-bottom: .5rem
}

.card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: 1rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #efecf3
}

.card-header:first-child {
    border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0
}

.card-footer {
    padding: 1rem 1.25rem;
    background-color: transparent;
    border-top: 1px solid #efecf3
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px)
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -1rem;
    margin-left: -0.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.75rem - 1px)
}

.card-img, .card-img-top, .card-img-bottom {
    width: 100%
}

.card-img, .card-img-top {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px)
}

.card-img, .card-img-bottom {
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media(min-width: 500px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top, .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom, .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top, .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom, .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem;
    font-size: 1rem;
    color: #454056;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out, border-radius .15s ease
}

@media(prefers-reduced-motion: reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: #3b82f6;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 #efecf3
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd5631'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23454056'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1rem;
    transition: transform .2s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #efecf3;
    outline: 0;
    box-shadow: unset
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: #fff;
    border: 1px solid #efecf3
}

.accordion-item:first-of-type {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem
}

.accordion-body {
    padding: 1rem 1rem
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    list-style: none;
    background-color: transparent
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .625rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .625rem;
    color: #9691a4;
    content: var(--bs-breadcrumb-divider, "\e952")
        /* rtl: var(--bs-breadcrumb-divider, "\e951") */
}

.breadcrumb-item.active {
    color: #3b82f6
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #454056;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #3b82f6;
    background-color: transparent;
    border-color: transparent
}

.page-link:focus {
    z-index: 3;
    color: #fd3509;
    background-color: transparent;
    outline: 0;
    box-shadow: none
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #3b82f6;
    border-color: transparent
}

.page-item.disabled .page-link {
    color: rgba(69, 64, 86, .4);
    pointer-events: none;
    background-color: transparent;
    border-color: transparent
}

.page-link {
    padding: 0 .475rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem
}

.pagination-lg .page-link {
    padding: 0 .55rem;
    font-size: 1.125rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem
}

.pagination-sm .page-link {
    padding: 0 .4rem;
    font-size: 0.875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.badge {
    display: inline-block;
    padding: .4375em .75em;
    font-size: 0.8125em;
    font-weight: normal;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .375rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .5rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #3b82f6;
    background-color: #ffeeea;
    border-color: #fec4b7
}

.alert-primary .alert-link {
    color: #ca4527
}

.alert-accent {
    color: #5d3cf2;
    background-color: #efecfe;
    border-color: #c6bbfa
}

.alert-accent .alert-link {
    color: #4a30c2
}

.alert-secondary {
    color: #f5f4f8;
    background-color: #fefefe;
    border-color: #fcfbfd
}

.alert-secondary .alert-link {
    color: #c4c3c6
}

.alert-success {
    color: #07c98b;
    background-color: #e6faf3;
    border-color: #a8ecd6
}

.alert-success .alert-link {
    color: #06a16f
}

.alert-info {
    color: #3c76f2;
    background-color: #ecf1fe;
    border-color: #bbcffa
}

.alert-info .alert-link {
    color: #305ec2
}

.alert-warning {
    color: #fdbc31;
    background-color: #fff8ea;
    border-color: #fee8b7
}

.alert-warning .alert-link {
    color: #ca9627
}

.alert-danger {
    color: #f23c49;
    background-color: #feeced;
    border-color: #fabbbf
}

.alert-danger .alert-link {
    color: #c2303a
}

.alert-light {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.alert-light .alert-link {
    color: #ccc
}

.alert-dark {
    color: #1f1b2d;
    background-color: #e9e8ea;
    border-color: #b1afb6
}

.alert-dark .alert-link {
    color: #191624
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #efecf3;
    border-radius: .375rem;
    box-shadow: none
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #3b82f6;
    transition: width .6s ease
}

@media(prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media(prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .75rem
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>li::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: #666276;
    text-align: inherit
}

.list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #3b82f6;
    text-decoration: none;
    background-color: transparent
}

.list-group-item-action:active {
    color: #3b82f6;
    background-color: transparent
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1rem;
    color: #454056;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .08)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #9691a4;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #3b82f6;
    border-color: #3b82f6
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .75rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: .75rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media(min-width: 500px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .75rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: .75rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .75rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: .75rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .75rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: .75rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .75rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: .75rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: .75rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: .75rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #000;
    background-color: #ffe6e0
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #000;
    background-color: #e6cfca
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-accent {
    color: #5d3cf2;
    background-color: #e7e2fd
}

.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
    color: #5d3cf2;
    background-color: #d0cbe4
}

.list-group-item-accent.list-group-item-action.active {
    color: #fff;
    background-color: #5d3cf2;
    border-color: #5d3cf2
}

.list-group-item-secondary {
    color: #000;
    background-color: #fefdfe
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #000;
    background-color: #e5e4e5
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-success {
    color: #000;
    background-color: #daf7ee
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #000;
    background-color: #c4ded6
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-info {
    color: #000;
    background-color: #e2eafd
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #000;
    background-color: #cbd3e4
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-warning {
    color: #000;
    background-color: #fff5e0
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #000;
    background-color: #e6ddca
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-danger {
    color: #000;
    background-color: #fde2e4
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #000;
    background-color: #e4cbcd
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-light {
    color: #000;
    background-color: #fff
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #000;
    background-color: #e6e6e6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-dark {
    color: #1f1b2d;
    background-color: #dddde0
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1f1b2d;
    background-color: #c7c7ca
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f1b2d;
    border-color: #1f1b2d
}

.btn-close {
    box-sizing: content-box;
    width: .75em;
    height: .75em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
    border: 0;
    border-radius: .5rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1
}

.btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12);
    border-radius: .5rem
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: .75rem
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #9691a4;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px)
}

.toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    opacity:0;
}
.open-modal{
    opacity: 1;
    animation: taadaa 0.7s;
}

@keyframes taadaa { 
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .2s ease-out;
    transform: scale(0.9)
}

@media(prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #efecf3;
    border-radius: .75rem;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08);
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #09080d
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .6
}

/* styles.css */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    display: none;
  }
  
  .backdrop.show {
    display: block;
    animation: fadeIn 0.3s;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .backdrop.fade {
    animation: fadeOut 0.3s;
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.125rem;
    border-bottom: 1px solid #efecf3;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem .5625rem;
    margin: -0.5rem -0.5625rem -0.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.125rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #efecf3;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px)
}

.modal-footer>* {
    margin: .375rem
}

@media(min-width: 500px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-content {
        box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 800px
    }
}

@media(min-width: 1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media(max-width: 499.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.75rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
    padding: .4rem 0
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
    padding: 0 .4rem
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
    padding: .4rem 0
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
    top: 0
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
    padding: 0 .4rem
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .375rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0
        /* rtl:ignore */
    ;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #efecf3;
    border-radius: .75rem;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-top>.popover-arrow, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
    bottom: calc(-0.5rem - 1px)
}

.bs-popover-top>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: #efecf3
}

.bs-popover-top>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-end>.popover-arrow, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
    left: calc(-0.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-end>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #efecf3
}

.bs-popover-end>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-bottom>.popover-arrow, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
    top: calc(-0.5rem - 1px)
}

.bs-popover-bottom>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #efecf3
}

.bs-popover-bottom>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #fff
}

.bs-popover-start>.popover-arrow, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
    right: calc(-0.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-start>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #efecf3
}

.bs-popover-start>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .75rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #1f1b2d;
    background-color: #fff;
    border-bottom: 1px solid #efecf3;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem 1rem;
    color: #666276
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: block
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
    transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media(prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
        transition: none
    }
}

.carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion: reduce) {
    .carousel-control-prev, .carousel-control-next {
        transition: none
    }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(360deg)
            /* rtl:ignore */
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
            /* rtl:ignore */
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: .15em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .1em
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: .75s linear infinite spinner-grow;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media(prefers-reduced-motion: reduce) {
    .spinner-border, .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1080;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    box-shadow: 0 .25rem .75rem -0.375rem rgba(31, 27, 45, .2), 0 .5rem 1rem rgba(31, 27, 45, .16);
    transition: transform .3s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #09080d
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .6
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1.5rem
}

.offcanvas-header .btn-close {
    padding: .625rem .75rem;
    margin-top: -0.625rem;
    margin-right: -0.75rem;
    margin-bottom: -0.625rem
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: initial
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1.25rem 1.5rem;
    overflow-y: auto
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 21rem;
    border-right: 0 solid transparent;
    transform: translateX(-100%)
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 21rem;
    border-left: 0 solid transparent;
    transform: translateX(100%)
}

.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 0 solid transparent;
    transform: translateY(-100%)
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 0 solid transparent;
    transform: translateY(100%)
}

.offcanvas.show {
    transform: none
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: .4
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: .15
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: .15
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.85) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.85) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #3b82f6
}

.link-primary:hover, .link-primary:focus {
    color: #fd785a
}

.link-accent {
    color: #5d3cf2
}

.link-accent:hover, .link-accent:focus {
    color: #4a30c2
}

.link-secondary {
    color: #f5f4f8
}

.link-secondary:hover, .link-secondary:focus {
    color: #f7f6f9
}

.link-success {
    color: #07c98b
}

.link-success:hover, .link-success:focus {
    color: #39d4a2
}

.link-info {
    color: #3c76f2
}

.link-info:hover, .link-info:focus {
    color: #6391f5
}

.link-warning {
    color: #fdbc31
}

.link-warning:hover, .link-warning:focus {
    color: #fdc95a
}

.link-danger {
    color: #f23c49
}

.link-danger:hover, .link-danger:focus {
    color: #f5636d
}

.link-light {
    color: #fff
}

.link-light:hover, .link-light:focus {
    color: #fff
}

.link-dark {
    color: #1f1b2d
}

.link-dark:hover, .link-dark:focus {
    color: #191624
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 1020
}

@media(min-width: 500px) {
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 768px) {
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 992px) {
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 1200px) {
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 1400px) {
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 1
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-10 {
    opacity: .1 !important
}

.opacity-15 {
    opacity: .15 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-35 {
    opacity: .35 !important
}

.opacity-40 {
    opacity: .4 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-60 {
    opacity: .6 !important
}

.opacity-65 {
    opacity: .65 !important
}

.opacity-70 {
    opacity: .7 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-80 {
    opacity: .8 !important
}

.opacity-90 {
    opacity: .9 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08) !important
}

.shadow-lg {
    box-shadow: 0 .25rem .75rem -0.375rem rgba(31, 27, 45, .2), 0 .5rem 1rem rgba(31, 27, 45, .16) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: 1px solid #efecf3 !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: 1px solid #efecf3 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: 1px solid #efecf3 !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: 1px solid #efecf3 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: 1px solid #efecf3 !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #3b82f6 !important
}

.border-accent {
    border-color: #5d3cf2 !important
}

.border-secondary {
    border-color: #f5f4f8 !important
}

.border-success {
    border-color: #07c98b !important
}

.border-info {
    border-color: #3c76f2 !important
}

.border-warning {
    border-color: #fdbc31 !important
}

.border-danger {
    border-color: #f23c49 !important
}

.border-light {
    border-color: rgba(255, 255, 255, .15) !important
}

.border-dark {
    border-color: #1f1b2d !important
}

.border-white {
    border-color: #fff !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -0.25rem !important
}

.m-n2 {
    margin: -0.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important
}

.mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important
}

.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
}

.mx-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important
}

.my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important
}

.my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
}

.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
}

.my-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
}

.mt-n1 {
    margin-top: -0.25rem !important
}

.mt-n2 {
    margin-top: -0.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -3rem !important
}

.me-n1 {
    margin-right: -0.25rem !important
}

.me-n2 {
    margin-right: -0.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -3rem !important
}

.mb-n1 {
    margin-bottom: -0.25rem !important
}

.mb-n2 {
    margin-bottom: -0.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -3rem !important
}

.ms-n1 {
    margin-left: -0.25rem !important
}

.ms-n2 {
    margin-left: -0.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -3rem !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1.125rem !important
}

.fs-lg {
    font-size: 1.125rem !important
}

.fs-base {
    font-size: 1rem !important
}

.fs-sm {
    font-size: 0.875rem !important
}

.fs-xs {
    font-size: 0.75rem !important
}

.fs-xxs {
    font-size: 0.625rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.5 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 1.5 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    color: #3b82f6 !important
}

.text-accent {
    color: #5d3cf2 !important
}

.text-secondary {
    color: #f5f4f8 !important
}

.text-success {
    color: #07c98b !important
}

.text-info {
    color: #3c76f2 !important
}

.text-warning {
    color: #fdbc31 !important
}

.text-danger {
    color: #f23c49 !important
}

.text-light {
    color: #fff !important
}

.text-dark {
    color: #1f1b2d !important
}

.text-white {
    color: #fff !important
}

.text-body {
    color: #666276 !important
}

.text-muted {
    color: #9691a4 !important
}

.text-nav {
    color: #454056 !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-accent {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-accent-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: .5rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: .375rem !important
}

.rounded-2 {
    border-radius: .5rem !important
}

.rounded-3 {
    border-radius: .75rem !important
}

.rounded-4 {
    border-radius: 1.875rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-top {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-end {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important
}

.rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-bottom {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.rounded-start {
    border-bottom-left-radius: .5rem !important;
    border-top-left-radius: .5rem !important
}

.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.bg-none {
    background: none !important
}

.bg-faded-primary {
    background-color: rgba(253, 86, 49, .1) !important
}

.bg-faded-accent {
    background-color: rgba(93, 60, 242, .1) !important
}

.bg-faded-success {
    background-color: rgba(7, 201, 139, .1) !important
}

.bg-faded-info {
    background-color: rgba(60, 118, 242, .1) !important
}

.bg-faded-warning {
    background-color: rgba(253, 188, 49, .15) !important
}

.bg-faded-danger {
    background-color: rgba(242, 60, 73, .1) !important
}

.bg-faded-light {
    background-color: rgba(255, 255, 255, .04) !important
}

.bg-faded-dark {
    background-color: rgba(31, 27, 45, .1) !important
}

.bg-image-none {
    background-image: none !important
}

.bg-size-cover {
    background-size: cover !important
}

.bg-size-contain {
    background-size: contain !important
}

.bg-position-center {
    background-position: center !important
}

.bg-position-center-y {
    background-position: left center !important
}

.bg-position-center-x {
    background-position: center top !important
}

.bg-position-top-right {
    background-position: top right !important
}

.bg-position-top-center {
    background-position: top center !important
}

.bg-position-top-left {
    background-position: top left !important
}

.bg-position-center-right {
    background-position: center right !important
}

.bg-position-center-left {
    background-position: center left !important
}

.bg-position-bottom-right {
    background-position: bottom right !important
}

.bg-position-bottom-left {
    background-position: bottom left !important
}

.bg-position-bottom-center {
    background-position: bottom center !important
}

.bg-repeat-0 {
    background-repeat: no-repeat !important
}

.bg-repeat-x {
    background-repeat: repeat-x !important
}

.bg-repeat-y {
    background-repeat: repeat-y !important
}

.text-shadow {
    text-shadow: 0 .0625rem rgba(0, 0, 0, .5) !important
}

.zindex-0 {
    z-index: 0 !important
}

.zindex-1 {
    z-index: 1 !important
}

.zindex-5 {
    z-index: 5 !important
}

.zindex-10 {
    z-index: 10 !important
}

@media(min-width: 500px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .border-sm {
        border: 1px solid #efecf3 !important
    }

    .border-sm-0 {
        border: 0 !important
    }

    .border-top-sm {
        border-top: 1px solid #efecf3 !important
    }

    .border-top-sm-0 {
        border-top: 0 !important
    }

    .border-end-sm {
        border-right: 1px solid #efecf3 !important
    }

    .border-end-sm-0 {
        border-right: 0 !important
    }

    .border-bottom-sm {
        border-bottom: 1px solid #efecf3 !important
    }

    .border-bottom-sm-0 {
        border-bottom: 0 !important
    }

    .border-start-sm {
        border-left: 1px solid #efecf3 !important
    }

    .border-start-sm-0 {
        border-left: 0 !important
    }

    .w-sm-25 {
        width: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .h-sm-25 {
        height: 25% !important
    }

    .h-sm-50 {
        height: 50% !important
    }

    .h-sm-75 {
        height: 75% !important
    }

    .h-sm-100 {
        height: 100% !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 3rem !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 3rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 3rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n1 {
        margin: -0.25rem !important
    }

    .m-sm-n2 {
        margin: -0.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mx-sm-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-sm-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-sm-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-sm-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-sm-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-sm-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-sm-n1 {
        margin-top: -0.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -0.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -1rem !important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n5 {
        margin-top: -3rem !important
    }

    .me-sm-n1 {
        margin-right: -0.25rem !important
    }

    .me-sm-n2 {
        margin-right: -0.5rem !important
    }

    .me-sm-n3 {
        margin-right: -1rem !important
    }

    .me-sm-n4 {
        margin-right: -1.5rem !important
    }

    .me-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ms-sm-n1 {
        margin-left: -0.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -0.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -1rem !important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n5 {
        margin-left: -3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 3rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }

    .rounded-sm {
        border-radius: .5rem !important
    }

    .rounded-sm-0 {
        border-radius: 0 !important
    }

    .rounded-sm-1 {
        border-radius: .375rem !important
    }

    .rounded-sm-2 {
        border-radius: .5rem !important
    }

    .rounded-sm-3 {
        border-radius: .75rem !important
    }

    .rounded-sm-4 {
        border-radius: 1.875rem !important
    }

    .rounded-sm-circle {
        border-radius: 50% !important
    }

    .rounded-sm-pill {
        border-radius: 50rem !important
    }

    .zindex-sm-0 {
        z-index: 0 !important
    }

    .zindex-sm-1 {
        z-index: 1 !important
    }

    .zindex-sm-5 {
        z-index: 5 !important
    }

    .zindex-sm-10 {
        z-index: 10 !important
    }
}

@media(min-width: 768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .border-md {
        border: 1px solid #efecf3 !important
    }

    .border-md-0 {
        border: 0 !important
    }

    .border-top-md {
        border-top: 1px solid #efecf3 !important
    }

    .border-top-md-0 {
        border-top: 0 !important
    }

    .border-end-md {
        border-right: 1px solid #efecf3 !important
    }

    .border-end-md-0 {
        border-right: 0 !important
    }

    .border-bottom-md {
        border-bottom: 1px solid #efecf3 !important
    }

    .border-bottom-md-0 {
        border-bottom: 0 !important
    }

    .border-start-md {
        border-left: 1px solid #efecf3 !important
    }

    .border-start-md-0 {
        border-left: 0 !important
    }

    .w-md-25 {
        width: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .h-md-25 {
        height: 25% !important
    }

    .h-md-50 {
        height: 50% !important
    }

    .h-md-75 {
        height: 75% !important
    }

    .h-md-100 {
        height: 100% !important
    }

    .h-md-auto {
        height: auto !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 3rem !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 3rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 3rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n1 {
        margin: -0.25rem !important
    }

    .m-md-n2 {
        margin: -0.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mx-md-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-md-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-md-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-md-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-md-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-md-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-md-n1 {
        margin-top: -0.25rem !important
    }

    .mt-md-n2 {
        margin-top: -0.5rem !important
    }

    .mt-md-n3 {
        margin-top: -1rem !important
    }

    .mt-md-n4 {
        margin-top: -1.5rem !important
    }

    .mt-md-n5 {
        margin-top: -3rem !important
    }

    .me-md-n1 {
        margin-right: -0.25rem !important
    }

    .me-md-n2 {
        margin-right: -0.5rem !important
    }

    .me-md-n3 {
        margin-right: -1rem !important
    }

    .me-md-n4 {
        margin-right: -1.5rem !important
    }

    .me-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n5 {
        margin-bottom: -3rem !important
    }

    .ms-md-n1 {
        margin-left: -0.25rem !important
    }

    .ms-md-n2 {
        margin-left: -0.5rem !important
    }

    .ms-md-n3 {
        margin-left: -1rem !important
    }

    .ms-md-n4 {
        margin-left: -1.5rem !important
    }

    .ms-md-n5 {
        margin-left: -3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 3rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }

    .rounded-md {
        border-radius: .5rem !important
    }

    .rounded-md-0 {
        border-radius: 0 !important
    }

    .rounded-md-1 {
        border-radius: .375rem !important
    }

    .rounded-md-2 {
        border-radius: .5rem !important
    }

    .rounded-md-3 {
        border-radius: .75rem !important
    }

    .rounded-md-4 {
        border-radius: 1.875rem !important
    }

    .rounded-md-circle {
        border-radius: 50% !important
    }

    .rounded-md-pill {
        border-radius: 50rem !important
    }

    .zindex-md-0 {
        z-index: 0 !important
    }

    .zindex-md-1 {
        z-index: 1 !important
    }

    .zindex-md-5 {
        z-index: 5 !important
    }

    .zindex-md-10 {
        z-index: 10 !important
    }
}

@media(min-width: 992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .border-lg {
        border: 1px solid #efecf3 !important
    }

    .border-lg-0 {
        border: 0 !important
    }

    .border-top-lg {
        border-top: 1px solid #efecf3 !important
    }

    .border-top-lg-0 {
        border-top: 0 !important
    }

    .border-end-lg {
        border-right: 1px solid #efecf3 !important
    }

    .border-end-lg-0 {
        border-right: 0 !important
    }

    .border-bottom-lg {
        border-bottom: 1px solid #efecf3 !important
    }

    .border-bottom-lg-0 {
        border-bottom: 0 !important
    }

    .border-start-lg {
        border-left: 1px solid #efecf3 !important
    }

    .border-start-lg-0 {
        border-left: 0 !important
    }

    .w-lg-25 {
        width: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .h-lg-25 {
        height: 25% !important
    }

    .h-lg-50 {
        height: 50% !important
    }

    .h-lg-75 {
        height: 75% !important
    }

    .h-lg-100 {
        height: 100% !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 3rem !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 3rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 3rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n1 {
        margin: -0.25rem !important
    }

    .m-lg-n2 {
        margin: -0.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mx-lg-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-lg-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-lg-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-lg-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-lg-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-lg-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-lg-n1 {
        margin-top: -0.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -0.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -1rem !important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n5 {
        margin-top: -3rem !important
    }

    .me-lg-n1 {
        margin-right: -0.25rem !important
    }

    .me-lg-n2 {
        margin-right: -0.5rem !important
    }

    .me-lg-n3 {
        margin-right: -1rem !important
    }

    .me-lg-n4 {
        margin-right: -1.5rem !important
    }

    .me-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ms-lg-n1 {
        margin-left: -0.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -0.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -1rem !important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n5 {
        margin-left: -3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 3rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }

    .rounded-lg {
        border-radius: .5rem !important
    }

    .rounded-lg-0 {
        border-radius: 0 !important
    }

    .rounded-lg-1 {
        border-radius: .375rem !important
    }

    .rounded-lg-2 {
        border-radius: .5rem !important
    }

    .rounded-lg-3 {
        border-radius: .75rem !important
    }

    .rounded-lg-4 {
        border-radius: 1.875rem !important
    }

    .rounded-lg-circle {
        border-radius: 50% !important
    }

    .rounded-lg-pill {
        border-radius: 50rem !important
    }

    .zindex-lg-0 {
        z-index: 0 !important
    }

    .zindex-lg-1 {
        z-index: 1 !important
    }

    .zindex-lg-5 {
        z-index: 5 !important
    }

    .zindex-lg-10 {
        z-index: 10 !important
    }
}

@media(min-width: 1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .border-xl {
        border: 1px solid #efecf3 !important
    }

    .border-xl-0 {
        border: 0 !important
    }

    .border-top-xl {
        border-top: 1px solid #efecf3 !important
    }

    .border-top-xl-0 {
        border-top: 0 !important
    }

    .border-end-xl {
        border-right: 1px solid #efecf3 !important
    }

    .border-end-xl-0 {
        border-right: 0 !important
    }

    .border-bottom-xl {
        border-bottom: 1px solid #efecf3 !important
    }

    .border-bottom-xl-0 {
        border-bottom: 0 !important
    }

    .border-start-xl {
        border-left: 1px solid #efecf3 !important
    }

    .border-start-xl-0 {
        border-left: 0 !important
    }

    .w-xl-25 {
        width: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .h-xl-25 {
        height: 25% !important
    }

    .h-xl-50 {
        height: 50% !important
    }

    .h-xl-75 {
        height: 75% !important
    }

    .h-xl-100 {
        height: 100% !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 3rem !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 3rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 3rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n1 {
        margin: -0.25rem !important
    }

    .m-xl-n2 {
        margin: -0.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mx-xl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-xl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-xl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xl-n1 {
        margin-top: -0.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -0.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -1rem !important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n5 {
        margin-top: -3rem !important
    }

    .me-xl-n1 {
        margin-right: -0.25rem !important
    }

    .me-xl-n2 {
        margin-right: -0.5rem !important
    }

    .me-xl-n3 {
        margin-right: -1rem !important
    }

    .me-xl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xl-n1 {
        margin-left: -0.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -0.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -1rem !important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n5 {
        margin-left: -3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 3rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }

    .rounded-xl {
        border-radius: .5rem !important
    }

    .rounded-xl-0 {
        border-radius: 0 !important
    }

    .rounded-xl-1 {
        border-radius: .375rem !important
    }

    .rounded-xl-2 {
        border-radius: .5rem !important
    }

    .rounded-xl-3 {
        border-radius: .75rem !important
    }

    .rounded-xl-4 {
        border-radius: 1.875rem !important
    }

    .rounded-xl-circle {
        border-radius: 50% !important
    }

    .rounded-xl-pill {
        border-radius: 50rem !important
    }

    .zindex-xl-0 {
        z-index: 0 !important
    }

    .zindex-xl-1 {
        z-index: 1 !important
    }

    .zindex-xl-5 {
        z-index: 5 !important
    }

    .zindex-xl-10 {
        z-index: 10 !important
    }
}

@media(min-width: 1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .border-xxl {
        border: 1px solid #efecf3 !important
    }

    .border-xxl-0 {
        border: 0 !important
    }

    .border-top-xxl {
        border-top: 1px solid #efecf3 !important
    }

    .border-top-xxl-0 {
        border-top: 0 !important
    }

    .border-end-xxl {
        border-right: 1px solid #efecf3 !important
    }

    .border-end-xxl-0 {
        border-right: 0 !important
    }

    .border-bottom-xxl {
        border-bottom: 1px solid #efecf3 !important
    }

    .border-bottom-xxl-0 {
        border-bottom: 0 !important
    }

    .border-start-xxl {
        border-left: 1px solid #efecf3 !important
    }

    .border-start-xxl-0 {
        border-left: 0 !important
    }

    .w-xxl-25 {
        width: 25% !important
    }

    .w-xxl-50 {
        width: 50% !important
    }

    .w-xxl-75 {
        width: 75% !important
    }

    .w-xxl-100 {
        width: 100% !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .h-xxl-25 {
        height: 25% !important
    }

    .h-xxl-50 {
        height: 50% !important
    }

    .h-xxl-75 {
        height: 75% !important
    }

    .h-xxl-100 {
        height: 100% !important
    }

    .h-xxl-auto {
        height: auto !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 3rem !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 3rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n1 {
        margin: -0.25rem !important
    }

    .m-xxl-n2 {
        margin: -0.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -3rem !important
    }

    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xxl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xxl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-xxl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xxl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xxl-n1 {
        margin-top: -0.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -0.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n5 {
        margin-top: -3rem !important
    }

    .me-xxl-n1 {
        margin-right: -0.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -0.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n5 {
        margin-right: -3rem !important
    }

    .mb-xxl-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xxl-n1 {
        margin-left: -0.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -0.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n5 {
        margin-left: -3rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 3rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }

    .rounded-xxl {
        border-radius: .5rem !important
    }

    .rounded-xxl-0 {
        border-radius: 0 !important
    }

    .rounded-xxl-1 {
        border-radius: .375rem !important
    }

    .rounded-xxl-2 {
        border-radius: .5rem !important
    }

    .rounded-xxl-3 {
        border-radius: .75rem !important
    }

    .rounded-xxl-4 {
        border-radius: 1.875rem !important
    }

    .rounded-xxl-circle {
        border-radius: 50% !important
    }

    .rounded-xxl-pill {
        border-radius: 50rem !important
    }

    .zindex-xxl-0 {
        z-index: 0 !important
    }

    .zindex-xxl-1 {
        z-index: 1 !important
    }

    .zindex-xxl-5 {
        z-index: 5 !important
    }

    .zindex-xxl-10 {
        z-index: 10 !important
    }
}

@media(min-width: 1200px) {
    .fs-1 {
        font-size: 2.5rem !important
    }

    .fs-2 {
        font-size: 2rem !important
    }

    .fs-3 {
        font-size: 1.75rem !important
    }

    .fs-4 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

html, .body {
    height: 100%
}

.body {
    display: flex;
    flex-direction: column;
}

.page-wrapper {
    flex: 1 0 auto
}

.footer {
    flex-shrink: 0
}

a {
    transition: color .2s ease-in-out
}

a:focus {
    outline: none
}

a:hover>.text-decoration-underline {
    text-decoration: none !important
}

a[class^=bg-], a[class*=bg-] {
    transition: background-color .25s ease
}

img, figure {
    max-width: 100%;
    height: auto;
    vertical-align: middle
}

svg {
    max-width: 100%
}

iframe {
    width: 100%
}

::-moz-selection {
    background: rgba(93, 60, 242, .15)
}

::selection {
    background: rgba(93, 60, 242, .15)
}

::-moz-selection {
    background: rgba(93, 60, 242, .15)
}

b, strong {
    font-weight: 700
}

small, .small {
    font-weight: inherit
}

button:focus {
    outline: none
}

.hr-vertical {
    width: 1px;
    height: 100% !important
}

.hr-light {
    color: rgba(255, 255, 255, .15)
}

@media(max-width: 991.98px) {
    .fixed-bottom-btn .footer {
        padding-bottom: 2.25rem
    }
}

@font-face {
    font-family: "finder-icons";
    src: url("../fonts/finder-iconsa5d4.ttf?7648j3") format("truetype"), url("../fonts/finder-iconsa5d4.woff?7648j3") format("woff"), url("../fonts/finder-iconsa5d4.svg?7648j3#finder-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block
}

[class^=fi-], [class*=" fi-"] {
    display: inline-block;
    font-family: "finder-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    line-height: 1
}

.fi-airbnb:before {
    content: ""
}

.fi-behance:before {
    content: ""
}

.fi-dribbble:before {
    content: ""
}

.fi-dropbox:before {
    content: ""
}

.fi-facebook-square:before {
    content: ""
}

.fi-facebook:before {
    content: ""
}

.fi-foursquare:before {
    content: ""
}

.fi-github:before {
    content: ""
}

.fi-google-drive:before {
    content: ""
}

.fi-google-play:before {
    content: ""
}

.fi-google:before {
    content: ""
}

.fi-hangouts:before {
    content: ""
}

.fi-instagram:before {
    content: ""
}

.fi-linkedin:before {
    content: ""
}

.fi-medium:before {
    content: ""
}

.fi-messenger:before {
    content: ""
}

.fi-odnoklassniki:before {
    content: ""
}

.fi-paypal:before {
    content: ""
}

.fi-pinterest:before {
    content: ""
}

.fi-rss:before {
    content: ""
}

.fi-skype:before {
    content: ""
}

.fi-slack:before {
    content: ""
}

.fi-snapchat:before {
    content: ""
}

.fi-soundcloud:before {
    content: ""
}

.fi-telegram-circle:before {
    content: ""
}

.fi-telegram:before {
    content: ""
}

.fi-tumblr:before {
    content: ""
}

.fi-twitch:before {
    content: ""
}

.fi-twitter:before {
    content: ""
}

.fi-viber:before {
    content: ""
}

.fi-vimeo:before {
    content: ""
}

.fi-vk:before {
    content: ""
}

.fi-wechat:before {
    content: ""
}

.fi-whatsapp:before {
    content: ""
}

.fi-youtube:before {
    content: ""
}

.fi-alarm:before {
    content: ""
}

.fi-alert-circle:before {
    content: ""
}

.fi-alert-octagon:before {
    content: ""
}

.fi-alert-triange:before {
    content: ""
}

.fi-align-justify:before {
    content: ""
}

.fi-align-left:before {
    content: ""
}

.fi-align-right:before {
    content: ""
}

.fi-anchor:before {
    content: ""
}

.fi-archive:before {
    content: ""
}

.fi-arrow-back-up:before {
    content: ""
}

.fi-arrow-back:before {
    content: ""
}

.fi-arrow-down:before {
    content: ""
}

.fi-arrow-forward-up:before {
    content: ""
}

.fi-arrow-forward:before {
    content: ""
}

.fi-arrow-left:before {
    content: ""
}

.fi-arrow-long-down:before {
    content: ""
}

.fi-arrow-long-left:before {
    content: ""
}

.fi-arrow-long-right:before {
    content: ""
}

.fi-arrow-long-up:before {
    content: ""
}

.fi-arrow-right:before {
    content: ""
}

.fi-arrow-up:before {
    content: ""
}

.fi-arrows-sort:before {
    content: ""
}

.fi-award:before {
    content: ""
}

.fi-bell-off:before {
    content: ""
}

.fi-bell-on:before {
    content: ""
}

.fi-bell:before {
    content: ""
}

.fi-bookmark-filled:before {
    content: ""
}

.fi-bookmark:before {
    content: ""
}

.fi-briefcase:before {
    content: ""
}

.fi-building:before {
    content: ""
}

.fi-calendar-alt:before {
    content: ""
}

.fi-calendar:before {
    content: ""
}

.fi-camera-plus:before {
    content: ""
}

.fi-car:before {
    content: ""
}

.fi-cart:before {
    content: ""
}

.fi-cash:before {
    content: ""
}

.fi-chat-circle:before {
    content: ""
}

.fi-chat-left:before {
    content: ""
}

.fi-chat-right:before {
    content: ""
}

.fi-check-circle:before {
    content: ""
}

.fi-check:before {
    content: ""
}

.fi-checkbox-checked-alt:before {
    content: ""
}

.fi-checkbox-checked:before {
    content: ""
}

.fi-checkbox:before {
    content: ""
}

.fi-chevron-down:before {
    content: ""
}

.fi-chevron-left:before {
    content: ""
}

.fi-chevron-right:before {
    content: ""
}

.fi-chevron-up:before {
    content: ""
}

.fi-chevrons-left:before {
    content: ""
}

.fi-chevrons-right:before {
    content: ""
}

.fi-clock:before {
    content: ""
}

.fi-cloud-download:before {
    content: ""
}

.fi-cloud-upload:before {
    content: ""
}

.fi-corner-down-left:before {
    content: ""
}

.fi-corner-down-right:before {
    content: ""
}

.fi-corner-left-down:before {
    content: ""
}

.fi-corner-left-up:before {
    content: ""
}

.fi-corner-right-down:before {
    content: ""
}

.fi-corner-right-up:before {
    content: ""
}

.fi-corner-up-left:before {
    content: ""
}

.fi-corner-up-right:before {
    content: ""
}

.fi-credit-card-off:before {
    content: ""
}

.fi-credit-card:before {
    content: ""
}

.fi-cup:before {
    content: ""
}

.fi-dashboard:before {
    content: ""
}

.fi-device-desktop:before {
    content: ""
}

.fi-device-laptop:before {
    content: ""
}

.fi-device-mobile:before {
    content: ""
}

.fi-device-tablet:before {
    content: ""
}

.fi-dislike:before {
    content: ""
}

.fi-dots-horisontal:before {
    content: ""
}

.fi-dots-vertical:before {
    content: ""
}

.fi-download-file:before {
    content: ""
}

.fi-download:before {
    content: ""
}

.fi-edit:before {
    content: ""
}

.fi-expand:before {
    content: ""
}

.fi-external-link:before {
    content: ""
}

.fi-eye-off:before {
    content: ""
}

.fi-eye-on:before {
    content: ""
}

.fi-file-clean:before {
    content: ""
}

.fi-file:before {
    content: ""
}

.fi-filter-alt-horizontal:before {
    content: ""
}

.fi-filter-alt-vertical:before {
    content: ""
}

.fi-filter-off:before {
    content: ""
}

.fi-filter:before {
    content: ""
}

.fi-flag:before {
    content: ""
}

.fi-flame:before {
    content: ""
}

.fi-folder-minus:before {
    content: ""
}

.fi-folder-off:before {
    content: ""
}

.fi-folder-plus:before {
    content: ""
}

.fi-folder-x:before {
    content: ""
}

.fi-folder:before {
    content: ""
}

.fi-folders:before {
    content: ""
}

.fi-footer:before {
    content: ""
}

.fi-friends:before {
    content: ""
}

.fi-geo:before {
    content: ""
}

.fi-gift:before {
    content: ""
}

.fi-glass:before {
    content: ""
}

.fi-globe:before {
    content: ""
}

.fi-grid:before {
    content: ""
}

.fi-header:before {
    content: ""
}

.fi-heart-filled:before {
    content: ""
}

.fi-heart:before {
    content: ""
}

.fi-help:before {
    content: ""
}

.fi-home:before {
    content: ""
}

.fi-image:before {
    content: ""
}

.fi-info-circle:before {
    content: ""
}

.fi-info-square:before {
    content: ""
}

.fi-layers:before {
    content: ""
}

.fi-like:before {
    content: ""
}

.fi-link:before {
    content: ""
}

.fi-list:before {
    content: ""
}

.fi-lock:before {
    content: ""
}

.fi-login:before {
    content: ""
}

.fi-logout:before {
    content: ""
}

.fi-mail:before {
    content: ""
}

.fi-man:before {
    content: ""
}

.fi-map-pin:before {
    content: ""
}

.fi-map-pins:before {
    content: ""
}

.fi-map:before {
    content: ""
}

.fi-microphone:before {
    content: ""
}

.fi-minus-circle:before {
    content: ""
}

.fi-minus-square:before {
    content: ""
}

.fi-minus:before {
    content: ""
}

.fi-music:before {
    content: ""
}

.fi-paperclip:before {
    content: ""
}

.fi-pencil:before {
    content: ""
}

.fi-phone:before {
    content: ""
}

.fi-pinned:before {
    content: ""
}

.fi-plane:before {
    content: ""
}

.fi-play-circle:before {
    content: ""
}

.fi-play-filled:before {
    content: ""
}

.fi-play:before {
    content: ""
}

.fi-plus-circle:before {
    content: ""
}

.fi-plus-square:before {
    content: ""
}

.fi-plus:before {
    content: ""
}

.fi-power:before {
    content: ""
}

.fi-refresh:before {
    content: ""
}

.fi-reply:before {
    content: ""
}

.fi-rotate-left:before {
    content: ""
}

.fi-rotate-right:before {
    content: ""
}

.fi-route:before {
    content: ""
}

.fi-search:before {
    content: ""
}

.fi-send:before {
    content: ""
}

.fi-settings:before {
    content: ""
}

.fi-share:before {
    content: ""
}

.fi-sidebar-left:before {
    content: ""
}

.fi-sidebar-right:before {
    content: ""
}

.fi-star-filled:before {
    content: ""
}

.fi-star-half:before {
    content: ""
}

.fi-star:before {
    content: ""
}

.fi-switch-horizontal:before {
    content: ""
}

.fi-switch-vertical:before {
    content: ""
}

.fi-ticket:before {
    content: ""
}

.fi-trash:before {
    content: ""
}

.fi-truck:before {
    content: ""
}

.fi-unlock:before {
    content: ""
}

.fi-upload-file:before {
    content: ""
}

.fi-upload:before {
    content: ""
}

.fi-user-check:before {
    content: ""
}

.fi-user-minus:before {
    content: ""
}

.fi-user-plus:before {
    content: ""
}

.fi-user-x:before {
    content: ""
}

.fi-user:before {
    content: ""
}

.fi-users:before {
    content: ""
}

.fi-video-off:before {
    content: ""
}

.fi-video:before {
    content: ""
}

.fi-wallet:before {
    content: ""
}

.fi-woman:before {
    content: ""
}

.fi-x-circle:before {
    content: ""
}

.fi-x-square:before {
    content: ""
}

.fi-x:before {
    content: ""
}

.fi-zoom-in:before {
    content: ""
}

.fi-zoom-out:before {
    content: ""
}

.fi-accounting:before {
    content: ""
}

.fi-apartment:before {
    content: ""
}

.fi-bath:before {
    content: ""
}

.fi-bed:before {
    content: ""
}

.fi-billboard-house:before {
    content: ""
}

.fi-cafe:before {
    content: ""
}

.fi-calculator:before {
    content: ""
}

.fi-cctv:before {
    content: ""
}

.fi-cocktail:before {
    content: ""
}

.fi-computer:before {
    content: ""
}

.fi-disco-ball:before {
    content: ""
}

.fi-dish:before {
    content: ""
}

.fi-double-bed:before {
    content: ""
}

.fi-dumbell:before {
    content: ""
}

.fi-entertainment:before {
    content: ""
}

.fi-gearbox:before {
    content: ""
}

.fi-hotel-bell:before {
    content: ""
}

.fi-house-chosen:before {
    content: ""
}

.fi-iron:before {
    content: ""
}

.fi-laundry:before {
    content: ""
}

.fi-makeup:before {
    content: ""
}

.fi-meds:before {
    content: ""
}

.fi-museum:before {
    content: ""
}

.fi-no-smoke:before {
    content: ""
}

.fi-parking:before {
    content: ""
}

.fi-pet:before {
    content: ""
}

.fi-petrol:before {
    content: ""
}

.fi-pie-chart:before {
    content: ""
}

.fi-plant:before {
    content: ""
}

.fi-real-estate-buy:before {
    content: ""
}

.fi-real-estate-house:before {
    content: ""
}

.fi-rent:before {
    content: ""
}

.fi-security:before {
    content: ""
}

.fi-shop:before {
    content: ""
}

.fi-shopping-bag:before {
    content: ""
}

.fi-single-bed:before {
    content: ""
}

.fi-snowflake:before {
    content: ""
}

.fi-spa:before {
    content: ""
}

.fi-swimming-pool:before {
    content: ""
}

.fi-thermometer:before {
    content: ""
}

.fi-tv:before {
    content: ""
}

.fi-wifi:before {
    content: ""
}

.fi-quote:before {
    content: ""
}

.fi-xing:before {
    content: ""
}

.fi-discord:before {
    content: ""
}

.fi-tiktok:before {
    content: ""
}

.fi-education:before {
    content: ""
}

.fi-code:before {
    content: ""
}

h1, .h1, .fs-1 {
    line-height: 1.2
}

h2, .h2, .fs-2 {
    line-height: 1.25
}

h3, .h3, .fs-3 {
    line-height: 1.3
}

h4, .h4, .fs-4 {
    line-height: 1.35
}

h5, .h5, .fs-5 {
    line-height: 1.4
}

h6, .h6, .fs-6 {
    line-height: 1.4
}

ul li, ol li, dt li {
    margin-bottom: .25rem
}

dt {
    color: #1f1b2d
}

.blockquote {
    position: relative;
    color: #1f1b2d;
    font-weight: 700
}

.blockquote::before {
    display: inline-block;
    font-family: "finder-icons";
    font-size: 3rem;
    color: #3b82f6;
    content: ""
}

.blockquote-footer {
    margin-top: 0
}

:not(pre)>code[class*=language-], pre[class*=language-], pre {
    margin: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #1f1b2d;
    font-size: .875em
}

pre {
    padding-right: 1rem;
    padding-left: 1rem
}

.code-toolbar :not(pre)>code[class*=language-], .code-toolbar pre[class*=language-] {
    padding-top: 2rem
}

code[class*=language-], pre[class*=language-], pre {
    color: #fff;
    text-shadow: none
}

.line-numbers .line-numbers-rows {
    border-right: 1px solid rgba(255, 255, 255, .15)
}

.line-numbers .line-numbers-rows>span::before {
    color: rgba(255, 255, 255, .4)
}

div.code-toolbar>.toolbar {
    opacity: 1
}

div.code-toolbar>.toolbar>.toolbar-item>a, div.code-toolbar>.toolbar>.toolbar-item>button, div.code-toolbar>.toolbar .toolbar-item>span {
    display: inline-block;
    margin: .375rem .5rem;
    padding: .25rem .75rem;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
    border-radius: .375rem;
    border: 1px solid rgba(253, 86, 49, .35);
    background-color: rgba(253, 86, 49, .12);
    color: #3b82f6;
    font-size: .75rem;
    font-weight: 700;
    box-shadow: none
}

div.code-toolbar>.toolbar>.toolbar-item>a:focus, div.code-toolbar>.toolbar>.toolbar-item>button:focus, div.code-toolbar>.toolbar .toolbar-item>span:focus {
    outline: none;
    box-shadow: none
}

div.code-toolbar>.toolbar>.toolbar-item>a:hover, div.code-toolbar>.toolbar>.toolbar-item>button:hover, div.code-toolbar>.toolbar .toolbar-item>span:hover {
    background-color: #3b82f6;
    color: #fff !important
}

.token.boolean, .token.constant, .token.deleted, .token.number, .token.property, .token.symbol, .token.tag {
    color: #47abfd
}

.token.cdata, .token.comment, .token.doctype, .token.prolog {
    color: rgba(255, 255, 255, .4)
}

.token.attr-name, .token.builtin, .token.char, .token.inserted, .token.selector {
    color: #a8e1ff
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.token.atrule, .token.attr-value, .token.keyword, .token.string {
    color: #f69733
}

.language-css .token.string, .style .token.string, .token.entity, .token.operator, .token.url {
    color: #35bcba;
    background-color: transparent
}

.token.class-name, .token.function {
    color: #35bcba;
    background-color: transparent
}

.table:not(.table-dark) thead:not(.thead-dark) th, .table:not(.table-dark) tbody th {
    color: #1f1b2d !important
}

.table:not(.table-dark) td {
    color: #666276 !important
}

.table-dark td {
    color: rgba(255, 255, 255, .75)
}

.table-secondary, .table-secondary>th, .table-secondary>td {
    background-color: #f5f4f8
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #c8bdd5
}

.table.table-dark>:not(:last-child)>:last-child>* {
    border-bottom-color: rgba(255, 255, 255, .5)
}

.table>:not(:first-child) {
    border-top: 0
}

.form-control:disabled, .form-select:disabled {
    cursor: not-allowed
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
    display: none
}

.form-control.border-top-0:focus {
    box-shadow: none
}

.password-toggle {
    position: relative
}

.password-toggle .form-control {
    padding-right: 3rem
}

.password-toggle-btn {
    position: absolute;
    top: 50%;
    right: .625rem;
    margin-bottom: 0;
    padding: .5rem;
    transform: translateY(-50%);
    font-size: 1rem;
    line-height: 1;
    cursor: pointer
}

.password-toggle-btn .password-toggle-indicator {
    transition: color .2s ease-in-out;
    color: #9691a4;
    font-family: "finder-icons";
    font-size: 1.125em;
    font-style: normal
}

.password-toggle-btn .password-toggle-indicator::before {
    content: ""
}

.password-toggle-btn .password-toggle-indicator:hover {
    color: #454056
}

.password-toggle-btn .password-toggle-check {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0
}

.password-toggle-btn .password-toggle-check:checked~.password-toggle-indicator::before {
    content: ""
}

.form-control-light+.password-toggle-btn .password-toggle-indicator {
    color: rgba(255, 255, 255, .5)
}

.form-control-light+.password-toggle-btn .password-toggle-indicator:hover {
    color: #fff
}

.was-validated .password-toggle .form-control:invalid, .was-validated .password-toggle .form-control.is-invalid, .was-validated .password-toggle .form-control:valid, .was-validated .password-toggle .form-control.is-valid {
    padding-right: 4rem
}

.was-validated .password-toggle .form-control:invalid+.password-toggle-btn, .was-validated .password-toggle .form-control.is-invalid+.password-toggle-btn, .was-validated .password-toggle .form-control:valid+.password-toggle-btn, .was-validated .password-toggle .form-control.is-valid+.password-toggle-btn {
    right: 2.1rem
}

.form-switch .form-check-input {
    height: 1.125rem;
    border: 0;
    background-color: #cdc9d5
}

.form-switch .form-check-input:checked {
    background-color: #07c98b
}

.valid-tooltip, .invalid-tooltip {
    position: static
}

.valid-tooltip {
    background-color: rgba(7, 201, 139, .1);
    color: #07c98b
}

.invalid-tooltip {
    background-color: rgba(242, 60, 73, .1);
    color: #f23c49
}

.was-validated :valid~.valid-tooltip, .was-validated :invalid~.invalid-tooltip, .is-valid~.valid-tooltip, .is-invalid~.invalid-tooltip {
    display: table
}

.was-validated :valid:not(:required):not(.btn), .is-valid:not(:required):not(.btn) {
    border-color: #d5d2dc !important;
    background-image: none
}

.was-validated :valid:not(:required):not(.btn):focus, .is-valid:not(:required):not(.btn):focus {
    border-color: #feab98 !important;
    box-shadow: 0 0 0 .125rem rgba(253, 86, 49, .25)
}

.was-validated :valid:not(:required):not(.btn).form-control-light, .was-validated :valid:not(:required):not(.btn).form-select-light, .is-valid:not(:required):not(.btn).form-control-light, .is-valid:not(:required):not(.btn).form-select-light {
    border-color: rgba(255, 255, 255, .12) !important
}

.was-validated :valid:not(:required):not(.btn).form-control-light:focus, .was-validated :valid:not(:required):not(.btn).form-select-light:focus, .is-valid:not(:required):not(.btn).form-control-light:focus, .is-valid:not(:required):not(.btn).form-select-light:focus {
    border-color: rgba(255, 255, 255, .23) !important;
    box-shadow: none
}

.was-validated .form-control-light:focus, .was-validated .form-select-light:focus {
    box-shadow: none !important
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: transparent;
    border-color: #3b82f6;
    box-shadow: none
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: transparent;
    border-color: #bbb7c5
}

.was-validated .form-check-input:valid~.form-check-label, .was-validated .form-check-input.is-valid~.form-check-label {
    color: inherit
}

.form-control-light, .form-select-light {
    background-color: rgba(255, 255, 255, .04);
    color: #fff;
    border-color: rgba(255, 255, 255, .12)
}

.form-control-light::-moz-placeholder, .form-select-light::-moz-placeholder {
    color: rgba(255, 255, 255, .5)
}

.form-control-light::placeholder, .form-select-light::placeholder {
    color: rgba(255, 255, 255, .5)
}

.form-control-light:focus, .form-select-light:focus {
    border-color: rgba(255, 255, 255, .23);
    background-color: rgba(255, 255, 255, .08);
    color: #fff;
    box-shadow: unset
}

.form-control-light:focus::-moz-placeholder, .form-select-light:focus::-moz-placeholder {
    color: #9691a4
}

.form-control-light:focus::placeholder, .form-select-light:focus::placeholder {
    color: #9691a4
}

.form-control-light:disabled, .form-select-light:disabled {
    background-color: rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .5);
    opacity: .5
}

.form-control-light:disabled::-moz-placeholder, .form-select-light:disabled::-moz-placeholder {
    color: rgba(255, 255, 255, .3)
}

.form-control-light:disabled::placeholder, .form-select-light:disabled::placeholder {
    color: rgba(255, 255, 255, .3)
}

.form-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-select-light {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.6%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
}

.form-select-light:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
}

.form-select-light option {
    color: initial
}

.form-check-light .form-check-input {
    border-color: rgba(255, 255, 255, .5)
}

.form-check-light .form-check-input:focus {
    border-color: rgba(255, 255, 255, .5)
}

.form-check-light .form-check-input:checked {
    border-color: #3b82f6
}

.form-check-light .form-check-label {
    color: #fff
}

.form-switch-light .form-check-input {
    background-color: rgba(255, 255, 255, .15)
}

.form-switch-light .form-check-input:checked {
    background-color: #07c98b
}

.form-switch-light .form-check-label {
    color: #fff
}

.range-slider-ui {
    height: .25rem;
    margin-top: 3rem;
    margin-bottom: 1.75rem;
    border: 0;
    background-color: #e2e0e7;
    box-shadow: none
}

.range-slider-ui .noUi-connect {
    background-color: #3b82f6
}

.range-slider-ui .noUi-handle {
    top: 50%;
    width: 1.125rem;
    height: 1.125rem;
    margin-top: -0.511875rem;
    border: 0;
    border-radius: 50%;
    background-color: #3b82f6;
    box-shadow: none
}

.range-slider-ui .noUi-handle::before {
    position: absolute;
    left: 50%;
    top: 50%;
    width: .6435rem;
    height: .6435rem;
    margin-top: -0.32175rem;
    margin-left: -0.32175rem;
    border-radius: 50%;
    background-color: #fff;
    content: ""
}

.range-slider-ui .noUi-handle::after {
    display: none
}

.range-slider-ui .noUi-handle:focus {
    outline: none
}

.range-slider-ui .noUi-marker-normal {
    display: none
}

.range-slider-ui .noUi-marker-horizontal.noUi-marker {
    width: 1px;
    background-color: #dfd9e7
}

.range-slider-ui .noUi-marker-horizontal.noUi-marker-large {
    height: .75rem
}

.range-slider-ui .noUi-value {
    padding-top: .125rem;
    color: #666276;
    font-size: .875rem
}

.range-slider-ui .noUi-tooltip {
    padding: .25rem .5rem;
    border: 1px solid #efecf3;
    border-radius: 50rem;
    background-color: #fff;
    color: #454056;
    font-size: .75rem;
    line-height: 1.2;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

html:not([dir=rtl]) .range-slider-ui.noUi-horizontal .noUi-handle {
    right: -0.5625rem
}

.range-slider-light .range-slider-ui {
    background-color: rgba(255, 255, 255, .2)
}

.subscription-status {
    display: table;
    border-radius: .375rem;
    font-size: .8125rem
}

.subscription-status.status-success, .subscription-status.status-error {
    margin-top: .5rem;
    padding: .25rem .5rem
}

.subscription-status.status-success {
    background-color: rgba(7, 201, 139, .1);
    color: #059869
}

.subscription-status.status-error {
    background-color: rgba(242, 60, 73, .1);
    color: #f23c49
}

.btn-primary {
    color: #fff
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.show {
    background-color: #3b82f6;
    border-color: #3b82f6;
    color: #fff;
    box-shadow: 0 0.25rem 0.25rem 0.125rem rgba(59, 130, 246, 0.1),
        0 0.375rem 0.75rem -0.125rem rgba(59, 130, 246, 0.4);
}
.btn-primary:disabled, .btn-primary.disabled {
    color: #fff
}

.btn-accent {
    color: #fff
}

.btn-accent:hover, .btn-accent:focus, .btn-accent:active, .btn-accent.active, .btn-accent.show {
    background-color: #411bf0;
    border-color: #411bf0;
    color: #fff;
    box-shadow: 0 .25rem .25rem .125rem rgba(93, 60, 242, .1), 0 .375rem .75rem -0.125rem rgba(93, 60, 242, .4)
}

.btn-accent:disabled, .btn-accent.disabled {
    color: #fff
}

.btn-success {
    color: #fff
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.show {
    background-color: #06a773;
    border-color: #06a773;
    color: #fff;
    box-shadow: 0 .25rem .25rem .125rem rgba(7, 201, 139, .1), 0 .375rem .75rem -0.125rem rgba(7, 201, 139, .4)
}

.btn-success:disabled, .btn-success.disabled {
    color: #fff
}

.btn-info {
    color: #fff
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.show {
    background-color: #1b5ef0;
    border-color: #1b5ef0;
    color: #fff;
    box-shadow: 0 .25rem .25rem .125rem rgba(60, 118, 242, .1), 0 .375rem .75rem -0.125rem rgba(60, 118, 242, .4)
}

.btn-info:disabled, .btn-info.disabled {
    color: #fff
}

.btn-warning {
    color: #fff
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.show {
    background-color: #fdb10e;
    border-color: #fdb10e;
    color: #fff;
    box-shadow: 0 .25rem .25rem .125rem rgba(253, 188, 49, .1), 0 .375rem .75rem -0.125rem rgba(253, 188, 49, .4)
}

.btn-warning:disabled, .btn-warning.disabled {
    color: #fff
}

.btn-danger {
    color: #fff
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.show {
    background-color: #f01b2a;
    border-color: #f01b2a;
    color: #fff;
    box-shadow: 0 .25rem .25rem .125rem rgba(242, 60, 73, .1), 0 .375rem .75rem -0.125rem rgba(242, 60, 73, .4)
}

.btn-danger:disabled, .btn-danger.disabled {
    color: #fff
}

.btn-dark {
    color: #fff
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.show {
    background-color: #100e17;
    border-color: #100e17;
    color: #fff;
    box-shadow: 0 .25rem .25rem .125rem rgba(31, 27, 45, .1), 0 .375rem .75rem -0.125rem rgba(31, 27, 45, .4)
}

.btn-dark:disabled, .btn-dark.disabled {
    color: #fff
}

.btn-secondary {
    border-color: #e4dfeb;
    color: #454056
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.show {
    border-color: #e4dfeb;
    color: #1f1b2d;
    box-shadow: 0 .25rem .25rem .125rem rgba(187, 183, 197, .1), 0 .375rem .75rem -0.125rem rgba(187, 183, 197, .4)
}

.btn-secondary:disabled, .btn-secondary.disabled {
    color: #9691a4
}

.btn-light {
    color: #454056
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active {
    color: #3b82f6
}

.btn-light:disabled, .btn-light.disabled {
    color: #9691a4
}

.btn.bg-gradient {
    border-color: transparent
}

[class^=btn-outline-]:hover, [class^=btn-outline-]:active, [class^=btn-outline-].active, [class^=btn-outline-].show, [class^=btn-outline-].dropdown-toggle.show, [class*=" btn-outline-"]:hover, [class*=" btn-outline-"]:active, [class*=" btn-outline-"].active, [class*=" btn-outline-"].show, [class*=" btn-outline-"].dropdown-toggle.show {
    color: #fff
}

.btn-outline-secondary {
    border-color: #d5d2dc;
    color: #454056
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.show, .btn-outline-secondary.dropdown-toggle.show {
    color: #1f1b2d;
    background-color: #f5f4f8;
    border-color: #d5d2dc !important
}

.btn-group>.btn-check:checked+.btn {
    border-color: #d5d2dc !important
}

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.show, .btn-outline-light.dropdown-toggle.show {
    background-color: #fff;
    color: #3b82f6
}

.btn-translucent-primary {
    background-color: rgba(253, 86, 49, .1)
}

.btn-translucent-accent {
    background-color: rgba(93, 60, 242, .1)
}

.btn-translucent-success {
    background-color: rgba(7, 201, 139, .1)
}

.btn-translucent-info {
    background-color: rgba(60, 118, 242, .1)
}

.btn-translucent-warning {
    background-color: rgba(253, 188, 49, .15)
}

.btn-translucent-danger {
    background-color: rgba(242, 60, 73, .1)
}

.btn-translucent-light {
    background-color: rgba(255, 255, 255, .04)
}

.btn-translucent-dark {
    background-color: rgba(31, 27, 45, .1)
}

.btn-translucent-primary {
    color: #3b82f6
}

.btn-translucent-primary:hover, .btn-translucent-primary:focus, .btn-translucent-primary:active, .btn-translucent-primary.active, .btn-translucent-primary.show {
    background-color: #3b82f6
}

.btn-translucent-accent {
    color: #5d3cf2
}

.btn-translucent-accent:hover, .btn-translucent-accent:focus, .btn-translucent-accent:active, .btn-translucent-accent.active, .btn-translucent-accent.show {
    background-color: #5d3cf2
}

.btn-translucent-success {
    color: #07c98b
}

.btn-translucent-success:hover, .btn-translucent-success:focus, .btn-translucent-success:active, .btn-translucent-success.active, .btn-translucent-success.show {
    background-color: #07c98b
}

.btn-translucent-info {
    color: #3c76f2
}

.btn-translucent-info:hover, .btn-translucent-info:focus, .btn-translucent-info:active, .btn-translucent-info.active, .btn-translucent-info.show {
    background-color: #3c76f2
}

.btn-translucent-warning {
    color: #fdbc31
}

.btn-translucent-warning:hover, .btn-translucent-warning:focus, .btn-translucent-warning:active, .btn-translucent-warning.active, .btn-translucent-warning.show {
    background-color: #fdbc31
}

.btn-translucent-danger {
    color: #f23c49
}

.btn-translucent-danger:hover, .btn-translucent-danger:focus, .btn-translucent-danger:active, .btn-translucent-danger.active, .btn-translucent-danger.show {
    background-color: #f23c49
}

.btn-translucent-dark {
    color: #1f1b2d
}

.btn-translucent-dark:hover, .btn-translucent-dark:focus, .btn-translucent-dark:active, .btn-translucent-dark.active, .btn-translucent-dark.show {
    background-color: #1f1b2d
}

[class^=btn-translucent-]:hover, [class^=btn-translucent-]:focus, [class^=btn-translucent-]:active, [class^=btn-translucent-].active, [class^=btn-translucent-].show, [class*=" btn-translucent-"]:hover, [class*=" btn-translucent-"]:focus, [class*=" btn-translucent-"]:active, [class*=" btn-translucent-"].active, [class*=" btn-translucent-"].show {
    color: #fff
}

.btn-translucent-light {
    color: #fff
}

.btn-translucent-light:hover, .btn-translucent-light:focus, .btn-translucent-light:active, .btn-translucent-light.active {
    background-color: #fff;
    color: #3b82f6
}

.btn-link {
    border: 0;
    background-color: transparent !important;
    color: #666276;
    font-weight: bold;
    text-decoration: none
}

.btn-link:hover {
    color: #3b82f6
}

.btn-link.btn-light {
    color: rgba(255, 255, 255, .7)
}

.btn-link.btn-light:hover {
    color: #fff
}

.btn.shadow-sm:hover, .btn-icon.shadow-sm:hover {
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12) !important
}

.btn-light-primary, .btn-light-primary.shadow-sm {
    background-color: #fff;
    color: #666276
}

.btn-light-primary:hover, .btn-light-primary:focus, .btn-light-primary:active, .btn-light-primary.active, .btn-light-primary.shadow-sm:hover, .btn-light-primary.shadow-sm:focus, .btn-light-primary.shadow-sm:active, .btn-light-primary.shadow-sm.active {
    color: #fff;
    background-color: #3b82f6;
    box-shadow: 0 .25rem .25rem .125rem rgba(253, 86, 49, .1), 0 .375rem .75rem -0.125rem rgba(253, 86, 49, .4) !important
}

.btn-light-primary.text-primary:hover, .btn-light-primary.text-primary:focus, .btn-light-primary.text-primary:active, .btn-light-primary.text-primary.active, .btn-light-primary.shadow-sm.text-primary:hover, .btn-light-primary.shadow-sm.text-primary:focus, .btn-light-primary.shadow-sm.text-primary:active, .btn-light-primary.shadow-sm.text-primary.active {
    color: #fff !important
}

.btn-xs {
    font-weight: normal;
    padding: .375rem .875rem;
    font-size: 0.75rem;
    border-radius: .375rem
}

.btn>[class^=fi-], .btn>[class*=" fi-"] {
    margin-top: -0.1875rem;
    vertical-align: middle
}

.btn>.spinner-border, .btn>.spinner-grow {
    vertical-align: middle
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0
}

.btn-icon>i {
    margin-top: .0625rem !important;
    font-size: 1.1em
}

.btn-icon.btn-sm, .input-group-sm .btn-icon.btn, .btn-group-sm>.btn-icon.btn {
    width: 2.25rem;
    height: 2.25rem
}

.btn-icon.btn-xs {
    width: 2rem;
    height: 2rem
}

.btn-icon.btn-xs>i {
    font-size: 1.2em
}

.btn-icon.btn-xxs {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .375rem;
    font-size: .75rem
}

.btn-icon.btn-xxs>i {
    margin-top: 0 !important;
    font-size: 1em
}

.btn-icon.btn-light.border {
    color: #666276;
    border-color: #e2dce9 !important
}

.btn-icon.btn-light.border:hover {
    color: #3b82f6;
    border-color: #3b82f6 !important
}

.btn-icon.btn-translucent-primary:hover {
    box-shadow: 0 .25rem .25rem .125rem rgba(253, 86, 49, .1), 0 .375rem .75rem -0.125rem rgba(253, 86, 49, .4)
}

.btn-group .border-light {
    border-color: rgba(255, 255, 255, .2) !important
}

.btn-market {
    display: inline-block;
    position: relative;
    padding: .65em 1.125em;
    border-radius: .5rem;
    background-image: linear-gradient(180deg, rgba(69, 64, 86, 0.765) 0%, rgba(69, 64, 86, 0.9) 100%);
    border: 1px solid #1f1b2d;
    box-shadow: 0 .25rem .75rem -0.375rem rgba(31, 27, 45, .2), 0 .5rem 1rem rgba(31, 27, 45, .16)
}

.btn-market>img, .btn-market>svg {
    display: block;
    margin-right: auto;
    margin-left: auto
}

.btn-scroll-top {
    display: block;
    position: fixed;
    right: -4.125rem;
    bottom: 1.25rem;
    width: 2.75rem;
    height: 2.75rem;
    transition: right 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity .3s, background-color .25s ease-in-out;
    border-radius: 50%;
    background-color: rgba(31, 27, 45, .25);
    color: #fff;
    text-align: center;
    opacity: 0;
    z-index: 1025
}

.btn-scroll-top>.btn-scroll-top-icon {
    font-size: .875rem;
    font-weight: bold;
    line-height: 2.625rem
}

.btn-scroll-top .btn-scroll-top-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    visibility: hidden;
    opacity: 0
}

.btn-scroll-top:hover {
    background-color: rgba(31, 27, 45, .5);
    color: #fff
}

.btn-scroll-top:hover .btn-scroll-top-tooltip {
    visibility: visible;
    opacity: 1
}

.btn-scroll-top.show {
    right: 1.25rem;
    opacity: 1
}

@media(max-width: 499.98px) {
    .btn-scroll-top {
        width: 2.2rem;
        height: 2.2rem;
        bottom: 1rem
    }

    .btn-scroll-top>.btn-scroll-top-icon {
        line-height: 2.075rem
    }

    .btn-scroll-top.show {
        right: 1rem
    }
}

@media(max-width: 991.98px) {
    .fixed-bottom-btn .btn-scroll-top {
        bottom: 3.25rem
    }
}

.btn-like, .btn-dislike {
    display: inline-flex;
    align-items: center;
    transition: color .25s ease-in-out;
    border: 0;
    background: none !important;
    color: #9691a4;
    font-size: .875rem
}

.btn-like:focus, .btn-dislike:focus {
    outline: none
}

.btn-like>i, .btn-dislike>i {
    margin-right: .25rem;
    font-size: 1.125rem
}

.btn-like.btn-light, .btn-dislike.btn-light {
    color: rgba(255, 255, 255, .7)
}

.btn-like>i {
    margin-top: -0.0625rem
}

.btn-like:hover {
    color: #07c98b
}

.btn-like.active {
    color: #07c98b
}

.btn-dislike>i {
    margin-bottom: -0.125rem
}

.btn-dislike:hover {
    color: #f23c49
}

.btn-dislike.active {
    color: #f23c49
}

.nav-item {
    margin-bottom: 0
}

.nav-link.active {
    color: #3b82f6
}

.nav-link.active:not([data-bs-toggle=dropdown]) {
    pointer-events: none;
    cursor: default
}

.nav-link>i {
    transition: opacity .25s ease-in-out
}

.nav-link:hover>i, .nav-link.active>i {
    opacity: 1 !important
}

.nav-link>img {
    margin-top: -0.125rem
}

.nav-light .nav-link, .nav-link-light {
    color: rgba(255, 255, 255, .65);
    text-decoration: none
}

.nav-light .nav-link:hover, .nav-light .nav-link:focus, .nav-link-light:hover, .nav-link-light:focus {
    color: #fff
}

.nav-light .nav-link.active, .nav-link-light.active {
    color: #fff
}

.nav-light .nav-link.disabled, .nav-link-light.disabled {
    color: rgba(255, 255, 255, .4)
}

.nav-light .nav-link>i, .nav-link-light>i {
    transition: opacity .25s ease-in-out
}

.nav-light .nav-link:hover>i, .nav-light .nav-link.active>i, .nav-link-light:hover>i, .nav-link-light.active>i {
    opacity: 1 !important
}

.nav-muted .nav-link, .nav-link-muted {
    text-decoration: none;
    color: #666276
}

.nav-muted .nav-link:hover, .nav-muted .nav-link:focus, .nav-link-muted:hover, .nav-link-muted:focus {
    color: #454056
}

.nav-muted .nav-link.active, .nav-link-muted.active {
    color: #454056
}

.nav-muted .nav-link.disabled, .nav-link-muted.disabled {
    color: #9691a4
}

.nav-muted .nav-link>i, .nav-link-muted>i {
    transition: opacity .25s ease-in-out
}

.nav-muted .nav-link:hover>i, .nav-muted .nav-link.active>i, .nav-link-muted:hover>i, .nav-link-muted.active>i {
    opacity: 1 !important
}

h1 .nav-link, h2 .nav-link, h3 .nav-link, h4 .nav-link, h5 .nav-link, h6 .nav-link, .h1 .nav-link, .h2 .nav-link, .h3 .nav-link, .h4 .nav-link, .h5 .nav-link, .h6 .nav-link {
    padding: initial;
    color: #454056
}

h1 .nav-link:hover, h2 .nav-link:hover, h3 .nav-link:hover, h4 .nav-link:hover, h5 .nav-link:hover, h6 .nav-link:hover, .h1 .nav-link:hover, .h2 .nav-link:hover, .h3 .nav-link:hover, .h4 .nav-link:hover, .h5 .nav-link:hover, .h6 .nav-link:hover {
    color: #3b82f6
}

h1 .nav-link.active, h2 .nav-link.active, h3 .nav-link.active, h4 .nav-link.active, h5 .nav-link.active, h6 .nav-link.active, .h1 .nav-link.active, .h2 .nav-link.active, .h3 .nav-link.active, .h4 .nav-link.active, .h5 .nav-link.active, .h6 .nav-link.active {
    color: #3b82f6
}

h1 .nav-link>i, h2 .nav-link>i, h3 .nav-link>i, h4 .nav-link>i, h5 .nav-link>i, h6 .nav-link>i, .h1 .nav-link>i, .h2 .nav-link>i, .h3 .nav-link>i, .h4 .nav-link>i, .h5 .nav-link>i, .h6 .nav-link>i {
    transition: opacity .25s ease-in-out
}

h1 .nav-link:hover>i, h1 .nav-link.active>i, h2 .nav-link:hover>i, h2 .nav-link.active>i, h3 .nav-link:hover>i, h3 .nav-link.active>i, h4 .nav-link:hover>i, h4 .nav-link.active>i, h5 .nav-link:hover>i, h5 .nav-link.active>i, h6 .nav-link:hover>i, h6 .nav-link.active>i, .h1 .nav-link:hover>i, .h1 .nav-link.active>i, .h2 .nav-link:hover>i, .h2 .nav-link.active>i, .h3 .nav-link:hover>i, .h3 .nav-link.active>i, .h4 .nav-link:hover>i, .h4 .nav-link.active>i, .h5 .nav-link:hover>i, .h5 .nav-link.active>i, .h6 .nav-link:hover>i, .h6 .nav-link.active>i {
    opacity: 1 !important
}

h1.text-light .nav-link, h1.text-white .nav-link, h2.text-light .nav-link, h2.text-white .nav-link, h3.text-light .nav-link, h3.text-white .nav-link, h4.text-light .nav-link, h4.text-white .nav-link, h5.text-light .nav-link, h5.text-white .nav-link, h6.text-light .nav-link, h6.text-white .nav-link, .h1.text-light .nav-link, .h1.text-white .nav-link, .h2.text-light .nav-link, .h2.text-white .nav-link, .h3.text-light .nav-link, .h3.text-white .nav-link, .h4.text-light .nav-link, .h4.text-white .nav-link, .h5.text-light .nav-link, .h5.text-white .nav-link, .h6.text-light .nav-link, .h6.text-white .nav-link {
    color: rgba(255, 255, 255, .65)
}

h1.text-light .nav-link:hover, h1.text-light .nav-link:focus, h1.text-white .nav-link:hover, h1.text-white .nav-link:focus, h2.text-light .nav-link:hover, h2.text-light .nav-link:focus, h2.text-white .nav-link:hover, h2.text-white .nav-link:focus, h3.text-light .nav-link:hover, h3.text-light .nav-link:focus, h3.text-white .nav-link:hover, h3.text-white .nav-link:focus, h4.text-light .nav-link:hover, h4.text-light .nav-link:focus, h4.text-white .nav-link:hover, h4.text-white .nav-link:focus, h5.text-light .nav-link:hover, h5.text-light .nav-link:focus, h5.text-white .nav-link:hover, h5.text-white .nav-link:focus, h6.text-light .nav-link:hover, h6.text-light .nav-link:focus, h6.text-white .nav-link:hover, h6.text-white .nav-link:focus, .h1.text-light .nav-link:hover, .h1.text-light .nav-link:focus, .h1.text-white .nav-link:hover, .h1.text-white .nav-link:focus, .h2.text-light .nav-link:hover, .h2.text-light .nav-link:focus, .h2.text-white .nav-link:hover, .h2.text-white .nav-link:focus, .h3.text-light .nav-link:hover, .h3.text-light .nav-link:focus, .h3.text-white .nav-link:hover, .h3.text-white .nav-link:focus, .h4.text-light .nav-link:hover, .h4.text-light .nav-link:focus, .h4.text-white .nav-link:hover, .h4.text-white .nav-link:focus, .h5.text-light .nav-link:hover, .h5.text-light .nav-link:focus, .h5.text-white .nav-link:hover, .h5.text-white .nav-link:focus, .h6.text-light .nav-link:hover, .h6.text-light .nav-link:focus, .h6.text-white .nav-link:hover, .h6.text-white .nav-link:focus {
    color: #fff
}

h1.text-light .nav-link.active, h1.text-white .nav-link.active, h2.text-light .nav-link.active, h2.text-white .nav-link.active, h3.text-light .nav-link.active, h3.text-white .nav-link.active, h4.text-light .nav-link.active, h4.text-white .nav-link.active, h5.text-light .nav-link.active, h5.text-white .nav-link.active, h6.text-light .nav-link.active, h6.text-white .nav-link.active, .h1.text-light .nav-link.active, .h1.text-white .nav-link.active, .h2.text-light .nav-link.active, .h2.text-white .nav-link.active, .h3.text-light .nav-link.active, .h3.text-white .nav-link.active, .h4.text-light .nav-link.active, .h4.text-white .nav-link.active, .h5.text-light .nav-link.active, .h5.text-white .nav-link.active, .h6.text-light .nav-link.active, .h6.text-white .nav-link.active {
    color: #fff
}

h1.text-light .nav-link.disabled, h1.text-white .nav-link.disabled, h2.text-light .nav-link.disabled, h2.text-white .nav-link.disabled, h3.text-light .nav-link.disabled, h3.text-white .nav-link.disabled, h4.text-light .nav-link.disabled, h4.text-white .nav-link.disabled, h5.text-light .nav-link.disabled, h5.text-white .nav-link.disabled, h6.text-light .nav-link.disabled, h6.text-white .nav-link.disabled, .h1.text-light .nav-link.disabled, .h1.text-white .nav-link.disabled, .h2.text-light .nav-link.disabled, .h2.text-white .nav-link.disabled, .h3.text-light .nav-link.disabled, .h3.text-white .nav-link.disabled, .h4.text-light .nav-link.disabled, .h4.text-white .nav-link.disabled, .h5.text-light .nav-link.disabled, .h5.text-white .nav-link.disabled, .h6.text-light .nav-link.disabled, .h6.text-white .nav-link.disabled {
    color: rgba(255, 255, 255, .4)
}

.nav-tabs {
    margin-bottom: 1.5rem
}

.nav-tabs .nav-link {
    border-radius: .5rem;
    box-shadow: none;
    background-color: #f5f4f8;
    color: #454056;
    font-weight: normal
}

.nav-tabs .nav-link>i {
    font-size: .85em
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link.show {
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08);
    color: #1f1b2d
}

.nav-tabs .nav-link.active {
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link[disabled] {
    background-color: #f9f8fa
}

.nav-tabs .nav-link>i {
    margin-top: -0.125rem;
    vertical-align: middle
}

.nav-tabs .nav-link.dropdown-toggle.active:hover, .nav-tabs .nav-link.dropdown-toggle.active.show {
    color: #3b82f6
}

.nav-tabs .nav-item {
    margin-bottom: 0
}

.nav-tabs .dropdown-menu {
    border-radius: .5rem
}

.nav-tabs-light .nav-item.show .nav-link {
    color: #3b82f6
}

.nav-tabs-light .nav-link {
    background-color: rgba(255, 255, 255, .04);
    color: #fff
}

.nav-tabs-light .nav-link:hover, .nav-tabs-light .nav-link.show {
    background-color: rgba(255, 255, 255, .1);
    color: #fff
}

.nav-tabs-light .nav-link.active {
    background-color: #fff;
    color: #3b82f6
}

.nav-pills .nav-link {
    background-color: #f5f4f8;
    font-weight: 400
}

.nav-pills .nav-link>i {
    font-size: .85em
}

.nav-pills .nav-link:hover {
    color: #454056;
    background-color: #f5f4f8;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.nav-pills .nav-link:focus {
    color: #454056
}

.nav-pills .nav-link.active {
    color: #3b82f6;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.nav-pills .nav-link:disabled, .nav-pills .nav-link.disabled {
    background-color: rgba(245, 244, 248, .5);
    color: rgba(69, 64, 86, .5)
}

.nav-pills.nav-pills-light .nav-link {
    background-color: rgba(255, 255, 255, .04);
    color: #fff;
    box-shadow: none
}

.nav-pills.nav-pills-light .nav-link:hover {
    background-color: rgba(255, 255, 255, .1);
    color: #fff;
    box-shadow: none
}

.nav-pills.nav-pills-light .nav-link.active {
    background-color: #fff;
    color: #3b82f6;
    box-shadow: none
}

.nav-pills.nav-pills-light .nav-link:disabled, .nav-pills.nav-pills-light .nav-link.disabled {
    background-color: rgba(255, 255, 255, .05);
    color: rgba(255, 255, 255, .35)
}

.nav-tabs:not(.nav-fill):not(.nav-justified) .nav-item, .nav-pills .nav-item {
    margin-right: .75rem
}

.nav-tabs:not(.nav-fill):not(.nav-justified) .nav-item:last-child, .nav-pills .nav-item:last-child {
    margin-right: 0
}

.nav-tabs:not(.nav-fill):not(.nav-justified).justify-content-center .nav-item, .nav-pills.justify-content-center .nav-item {
    margin-right: .375rem;
    margin-left: .375rem
}

.nav-tabs:not(.nav-fill):not(.nav-justified).justify-content-end .nav-item, .nav-pills.justify-content-end .nav-item {
    margin-right: 0;
    margin-left: .75rem
}

.nav-tabs:not(.nav-fill):not(.nav-justified).flex-column .nav-item, .nav-pills.flex-column .nav-item {
    margin-right: 0;
    margin-bottom: .75rem;
    margin-left: 0
}

.nav-tabs:not(.nav-fill):not(.nav-justified).flex-column .nav-item:last-child, .nav-pills.flex-column .nav-item:last-child {
    margin-bottom: 0
}

.card-nav {
    margin: 0;
    padding: 0;
    list-style: none
}

.card-nav li {
    margin-bottom: 0
}

.card-nav-link {
    position: relative;
    display: block;
    padding: 1rem 0;
    transition: color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out, border-color .25s ease-in-out;
    border-top: 1px solid #efecf3;
    color: #454056;
    text-decoration: none
}

.card-nav-link::before {
    position: absolute;
    display: none;
    top: 0;
    left: -1.25rem;
    width: .125rem;
    height: 100%;
    background-color: #3b82f6;
    content: ""
}

.card-nav-link:hover {
    color: #3b82f6
}

.card-nav-link.active {
    color: #3b82f6;
    pointer-events: none
}

.card-nav-link.active::before {
    display: block
}

.card-nav-link>i {
    margin-top: -0.125rem;
    transition: opacity .25s ease-in-out
}

.card-nav-link:hover>i, .card-nav-link.active>i {
    opacity: 1 !important
}

.card-nav-light .card-nav-link, .card-light .card-nav-link {
    border-top-color: rgba(255, 255, 255, .15);
    color: rgba(255, 255, 255, .65)
}

.card-nav-light .card-nav-link::before, .card-light .card-nav-link::before {
    background-color: #fff
}

.card-nav-light .card-nav-link:hover, .card-light .card-nav-link:hover {
    color: #fff
}

.card-nav-light .card-nav-link.active, .card-light .card-nav-link.active {
    color: #fff
}

.navbar-brand {
    font-weight: 700
}

.navbar-nav {
    margin-top: 1rem
}

.navbar-nav .nav-item {
    margin-bottom: .375rem;
    padding: .125rem 1rem;
    border: 1px solid transparent;
    border-radius: .5rem
}

.navbar-nav .dropdown-toggle {
    display: flex
}

.navbar-nav .dropdown-toggle::after {
    display: block;
    font-size: .75em;
    margin-top: .1875rem;
    margin-right: 0 !important;
    margin-left: auto
}

.navbar-nav .dropdown-menu {
    margin-bottom: .75rem;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    border-left: 2px solid transparent;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none
}

.navbar-nav .dropdown-menu .dropdown-menu {
    display: block;
    margin-bottom: 0;
    margin-left: 1rem;
    opacity: 1
}

.navbar-nav .dropdown-menu .dropdown-toggle::after {
    display: none
}

@media(min-width: 500px) {
    .navbar-expand-sm .navbar-nav {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        border-radius: 0
    }

    .navbar-expand-sm .navbar-nav .nav-item {
        margin-bottom: 0;
        padding: 0;
        border: 0;
        border-radius: 0
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding: .6875rem 1rem;
        font-size: .875rem
    }

    .navbar-expand-sm .navbar-nav .dropdown-toggle {
        display: block
    }

    .navbar-expand-sm .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        margin-top: 0;
        margin-left: .375rem;
        font-size: .6em
    }

    .navbar-expand-sm .dropdown-menu {
        margin-bottom: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #efecf3;
        border-radius: .75rem;
        background-color: #fff;
        box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
    }

    .navbar-expand-sm .dropdown-menu.dropdown-menu-dark {
        border-color: rgba(255, 255, 255, .15);
        background-color: #1f1b2d;
        box-shadow: none
    }

    .navbar-expand-sm .dropdown-menu>.dropdown>.dropdown-toggle {
        display: block;
        position: relative;
        padding-right: 1.5rem
    }

    .navbar-expand-sm .dropdown-menu>.dropdown>.dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        margin-top: -0.375rem;
        content: ""
    }

    .navbar-expand-sm .dropdown-menu>.dropdown .dropdown-menu {
        display: none;
        top: 0;
        right: auto;
        left: 100%;
        width: auto;
        margin-top: -0.5rem;
        margin-right: 0;
        margin-left: -0.375rem;
        padding-bottom: .5rem
    }

    .navbar-expand-sm .dropdown-menu>.dropdown:hover>.dropdown-menu {
        -webkit-animation: fade-in .25s ease-in-out;
        animation: fade-in .25s ease-in-out
    }

    .navbar-expand-sm .dropdown-menu.show {
        display: none
    }

    .navbar-expand-sm .dropdown-menu-end {
        left: auto;
        right: 0
    }

    .navbar-expand-sm .dropdown:hover>.dropdown-menu {
        display: block;
        -webkit-animation: slide-up .25s ease-in-out;
        animation: slide-up .25s ease-in-out
    }

    .navbar-expand-sm.navbar-light, .navbar-expand-sm.navbar-dark {
        background-color: transparent
    }
}

@media(min-width: 768px) {
    .navbar-expand-md .navbar-nav {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        border-radius: 0
    }

    .navbar-expand-md .navbar-nav .nav-item {
        margin-bottom: 0;
        padding: 0;
        border: 0;
        border-radius: 0
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding: .6875rem 1rem;
        font-size: .875rem
    }

    .navbar-expand-md .navbar-nav .dropdown-toggle {
        display: block
    }

    .navbar-expand-md .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        margin-top: 0;
        margin-left: .375rem;
        font-size: .6em
    }

    .navbar-expand-md .dropdown-menu {
        margin-bottom: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #efecf3;
        border-radius: .75rem;
        background-color: #fff;
        box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
    }

    .navbar-expand-md .dropdown-menu.dropdown-menu-dark {
        border-color: rgba(255, 255, 255, .15);
        background-color: #1f1b2d;
        box-shadow: none
    }

    .navbar-expand-md .dropdown-menu>.dropdown>.dropdown-toggle {
        display: block;
        position: relative;
        padding-right: 1.5rem
    }

    .navbar-expand-md .dropdown-menu>.dropdown>.dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        margin-top: -0.375rem;
        content: ""
    }

    .navbar-expand-md .dropdown-menu>.dropdown .dropdown-menu {
        display: none;
        top: 0;
        right: auto;
        left: 100%;
        width: auto;
        margin-top: -0.5rem;
        margin-right: 0;
        margin-left: -0.375rem;
        padding-bottom: .5rem
    }

    .navbar-expand-md .dropdown-menu>.dropdown:hover>.dropdown-menu {
        -webkit-animation: fade-in .25s ease-in-out;
        animation: fade-in .25s ease-in-out
    }

    .navbar-expand-md .dropdown-menu.show {
        display: none
    }

    .navbar-expand-md .dropdown-menu-end {
        left: auto;
        right: 0
    }

    .navbar-expand-md .dropdown:hover>.dropdown-menu {
        display: block;
        -webkit-animation: slide-up .25s ease-in-out;
        animation: slide-up .25s ease-in-out
    }

    .navbar-expand-md.navbar-light, .navbar-expand-md.navbar-dark {
        background-color: transparent
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        border-radius: 0
    }

    .navbar-expand-lg .navbar-nav .nav-item {
        margin-bottom: 0;
        padding: 0;
        border: 0;
        border-radius: 0
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: .6875rem 1rem;
        font-size: .875rem
    }

    .navbar-expand-lg .navbar-nav .dropdown-toggle {
        display: block
    }

    .navbar-expand-lg .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        margin-top: 0;
        margin-left: .375rem;
        font-size: .6em
    }

    .navbar-expand-lg .dropdown-menu {
        margin-bottom: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #efecf3;
        border-radius: .75rem;
        background-color: #fff;
        box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
    }

    .navbar-expand-lg .dropdown-menu.dropdown-menu-dark {
        border-color: rgba(255, 255, 255, .15);
        background-color: #1f1b2d;
        box-shadow: none
    }

    .navbar-expand-lg .dropdown-menu>.dropdown>.dropdown-toggle {
        display: block;
        position: relative;
        padding-right: 1.5rem
    }

    .navbar-expand-lg .dropdown-menu>.dropdown>.dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        margin-top: -0.375rem;
        content: ""
    }

    .navbar-expand-lg .dropdown-menu>.dropdown .dropdown-menu {
        display: none;
        top: 0;
        right: auto;
        left: 100%;
        width: auto;
        margin-top: -0.5rem;
        margin-right: 0;
        margin-left: -0.375rem;
        padding-bottom: .5rem
    }

    .navbar-expand-lg .dropdown-menu>.dropdown:hover>.dropdown-menu {
        -webkit-animation: fade-in .25s ease-in-out;
        animation: fade-in .25s ease-in-out
    }

    .navbar-expand-lg .dropdown-menu.show {
        display: none
    }

    .navbar-expand-lg .dropdown-menu-end {
        left: auto;
        right: 0
    }

    .navbar-expand-lg .dropdown:hover>.dropdown-menu {
        display: block;
        -webkit-animation: slide-up .25s ease-in-out;
        animation: slide-up .25s ease-in-out
    }

    .navbar-expand-lg.navbar-light, .navbar-expand-lg.navbar-dark {
        background-color: transparent
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl .navbar-nav {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        border-radius: 0
    }

    .navbar-expand-xl .navbar-nav .nav-item {
        margin-bottom: 0;
        padding: 0;
        border: 0;
        border-radius: 0
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding: .6875rem 1rem;
        font-size: .875rem
    }

    .navbar-expand-xl .navbar-nav .dropdown-toggle {
        display: block
    }

    .navbar-expand-xl .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        margin-top: 0;
        margin-left: .375rem;
        font-size: .6em
    }

    .navbar-expand-xl .dropdown-menu {
        margin-bottom: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #efecf3;
        border-radius: .75rem;
        background-color: #fff;
        box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
    }

    .navbar-expand-xl .dropdown-menu.dropdown-menu-dark {
        border-color: rgba(255, 255, 255, .15);
        background-color: #1f1b2d;
        box-shadow: none
    }

    .navbar-expand-xl .dropdown-menu>.dropdown>.dropdown-toggle {
        display: block;
        position: relative;
        padding-right: 1.5rem
    }

    .navbar-expand-xl .dropdown-menu>.dropdown>.dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        margin-top: -0.375rem;
        content: ""
    }

    .navbar-expand-xl .dropdown-menu>.dropdown .dropdown-menu {
        display: none;
        top: 0;
        right: auto;
        left: 100%;
        width: auto;
        margin-top: -0.5rem;
        margin-right: 0;
        margin-left: -0.375rem;
        padding-bottom: .5rem
    }

    .navbar-expand-xl .dropdown-menu>.dropdown:hover>.dropdown-menu {
        -webkit-animation: fade-in .25s ease-in-out;
        animation: fade-in .25s ease-in-out
    }

    .navbar-expand-xl .dropdown-menu.show {
        display: none
    }

    .navbar-expand-xl .dropdown-menu-end {
        left: auto;
        right: 0
    }

    .navbar-expand-xl .dropdown:hover>.dropdown-menu {
        display: block;
        -webkit-animation: slide-up .25s ease-in-out;
        animation: slide-up .25s ease-in-out
    }

    .navbar-expand-xl.navbar-light, .navbar-expand-xl.navbar-dark {
        background-color: transparent
    }
}

@media(min-width: 1400px) {
    .navbar-expand-xxl .navbar-nav {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        border-radius: 0
    }

    .navbar-expand-xxl .navbar-nav .nav-item {
        margin-bottom: 0;
        padding: 0;
        border: 0;
        border-radius: 0
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding: .6875rem 1rem;
        font-size: .875rem
    }

    .navbar-expand-xxl .navbar-nav .dropdown-toggle {
        display: block
    }

    .navbar-expand-xxl .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        margin-top: 0;
        margin-left: .375rem;
        font-size: .6em
    }

    .navbar-expand-xxl .dropdown-menu {
        margin-bottom: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #efecf3;
        border-radius: .75rem;
        background-color: #fff;
        box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
    }

    .navbar-expand-xxl .dropdown-menu.dropdown-menu-dark {
        border-color: rgba(255, 255, 255, .15);
        background-color: #1f1b2d;
        box-shadow: none
    }

    .navbar-expand-xxl .dropdown-menu>.dropdown>.dropdown-toggle {
        display: block;
        position: relative;
        padding-right: 1.5rem
    }

    .navbar-expand-xxl .dropdown-menu>.dropdown>.dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        margin-top: -0.375rem;
        content: ""
    }

    .navbar-expand-xxl .dropdown-menu>.dropdown .dropdown-menu {
        display: none;
        top: 0;
        right: auto;
        left: 100%;
        width: auto;
        margin-top: -0.5rem;
        margin-right: 0;
        margin-left: -0.375rem;
        padding-bottom: .5rem
    }

    .navbar-expand-xxl .dropdown-menu>.dropdown:hover>.dropdown-menu {
        -webkit-animation: fade-in .25s ease-in-out;
        animation: fade-in .25s ease-in-out
    }

    .navbar-expand-xxl .dropdown-menu.show {
        display: none
    }

    .navbar-expand-xxl .dropdown-menu-end {
        left: auto;
        right: 0
    }

    .navbar-expand-xxl .dropdown:hover>.dropdown-menu {
        display: block;
        -webkit-animation: slide-up .25s ease-in-out;
        animation: slide-up .25s ease-in-out
    }

    .navbar-expand-xxl.navbar-light, .navbar-expand-xxl.navbar-dark {
        background-color: transparent
    }
}

.navbar-expand .navbar-nav {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    border-radius: 0
}

.navbar-expand .navbar-nav .nav-item {
    margin-bottom: 0;
    padding: 0;
    border: 0;
    border-radius: 0
}

.navbar-expand .navbar-nav .nav-link {
    padding: .6875rem 1rem;
    font-size: .875rem
}

.navbar-expand .navbar-nav .dropdown-toggle {
    display: block
}

.navbar-expand .navbar-nav .dropdown-toggle::after {
    display: inline-block;
    margin-top: 0;
    margin-left: .375rem;
    font-size: .6em
}

.navbar-expand .dropdown-menu {
    margin-bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border: 1px solid #efecf3;
    border-radius: .75rem;
    background-color: #fff;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.navbar-expand .dropdown-menu.dropdown-menu-dark {
    border-color: rgba(255, 255, 255, .15);
    background-color: #1f1b2d;
    box-shadow: none
}

.navbar-expand .dropdown-menu>.dropdown>.dropdown-toggle {
    display: block;
    position: relative;
    padding-right: 1.5rem
}

.navbar-expand .dropdown-menu>.dropdown>.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.375rem;
    content: ""
}

.navbar-expand .dropdown-menu>.dropdown .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: -0.5rem;
    margin-right: 0;
    margin-left: -0.375rem;
    padding-bottom: .5rem
}

.navbar-expand .dropdown-menu>.dropdown:hover>.dropdown-menu {
    -webkit-animation: fade-in .25s ease-in-out;
    animation: fade-in .25s ease-in-out
}

.navbar-expand .dropdown-menu.show {
    display: none
}

.navbar-expand .dropdown-menu-end {
    left: auto;
    right: 0
}

.navbar-expand .dropdown:hover>.dropdown-menu {
    display: block;
    -webkit-animation: slide-up .25s ease-in-out;
    animation: slide-up .25s ease-in-out
}

.navbar-expand.navbar-light, .navbar-expand.navbar-dark {
    background-color: transparent
}

.navbar-light {
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    background-color: #fff
}

.navbar-light .nav-item {
    border-color: #efecf3
}

.navbar-light .nav-item:hover .nav-link:not(.disabled) {
    color: #3b82f6
}

.navbar-light .nav-item.active .nav-link:not(.disabled) {
    color: #3b82f6
}

.navbar-light .navbar-nav .dropdown-menu {
    border-left-color: #efecf3
}

.navbar-light.navbar-stuck {
    background-color: #fff;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.navbar-dark {
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    background-color: #1f1b2d
}

.navbar-dark .nav-item {
    border-color: rgba(255, 255, 255, .15)
}

.navbar-dark .nav-item:hover .nav-link:not(.disabled) {
    color: #fff
}

.navbar-dark .nav-item.active .nav-link:not(.disabled) {
    color: #fff
}

.navbar-dark .navbar-nav .dropdown-menu {
    border-left-color: rgba(255, 255, 255, .15)
}

.navbar-dark.navbar-stuck {
    background-color: #1f1b2d
}

.offcanvas {
    will-change: transform, box-shadow;
    transition: transform .4s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow .3s ease;
    box-shadow: none;
    visibility: visible !important
}

.offcanvas-header, .offcanvas-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 1.25rem 1.5rem
}

.bg-dark .offcanvas-header, .bg-dark .offcanvas-footer {
    background-color: rgba(255, 255, 255, .05)
}

.offcanvas-body {
    height: 100%
}

.offcanvas-body::-webkit-scrollbar {
    width: .1875rem;
    background-color: transparent;
    opacity: 0
}

.offcanvas-body::-webkit-scrollbar-thumb {
    background-color: #d5d2dc;
    border-radius: .09375rem
}

.offcanvas-body>.simplebar-track {
    display: block;
    background-color: transparent
}

.offcanvas-body .simplebar-vertical {
    margin-right: .1875rem
}

.offcanvas-body>:not(.card) .nav {
    flex-direction: column;
    margin-right: -1.5rem;
    margin-left: -1.5rem
}

.offcanvas-body>:not(.card) .nav>li {
    margin-bottom: 0
}

.offcanvas-body>:not(.card):not(.navbar-nav) .nav-link {
    padding: .3rem 1.5rem;
    font-weight: normal
}

.offcanvas-body>:not(.card):not(.navbar-nav) .nav-link.active {
    position: relative
}

.offcanvas-body>:not(.card):not(.navbar-nav) .nav-link.active::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: .125rem;
    height: 100%;
    background-color: #3b82f6;
    content: ""
}

.offcanvas-body>:not(.card) .nav-light .nav-link.active::before {
    background-color: #fff
}

.bg-dark .offcanvas-body::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .35)
}

.offcanvas-collapse .card-flush {
    border: 0
}

.offcanvas-collapse .card-flush>.card-body {
    padding: 0
}

@media(min-width: 992px) {
    .offcanvas-expand {
        transform: none;
        z-index: 1031
    }

    .offcanvas-expand.offcanvas-start, .offcanvas-expand.offcanvas-end, .offcanvas-expand.offcanvas-bottom {
        transform: none
    }

    .offcanvas-collapse {
        display: block;
        position: static;
        top: auto !important;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        transform: none !important;
        background-color: transparent
    }

    .offcanvas-collapse .offcanvas-header, .offcanvas-collapse .offcanvas-footer {
        display: none
    }

    .offcanvas-collapse .offcanvas-body {
        padding: 0;
        overflow: initial
    }

    .offcanvas-collapse .card-flush {
        border: 1px solid #efecf3
    }

    .offcanvas-collapse .card-flush>.card-body {
        padding: 1.25rem 1.25rem
    }

    .offcanvas-enabled-start {
        padding-left: 22.5rem
    }

    .offcanvas-enabled-end {
        padding-right: 22.5rem;
        padding-left: 0
    }
}

.simplebar-scrollbar::before {
    right: 0;
    left: 0;
    background-color: #d5d2dc;
    border-radius: .5rem;
    width:6px !important;
}

.simplebar-scrollbar.simplebar-visible::before {
    opacity: 0.5;
}

.simplebar-scrollbar.simplebar-visible {
    opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.simplebar-track {
    border-radius: .5rem;
    width:6px !important;
}

.simplebar-track.simplebar-vertical {
    width: .1875rem
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 0;
    bottom: 0
}

.simplebar-track.simplebar-horizontal {
    height: .1875rem
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: .1875rem;
    top: 0;
    bottom: 0
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
    right: 0;
    left: 0
}

[data-simplebar][data-simplebar-auto-hide=false] .simplebar-track {
    background-color: #f5f4f8
}

[data-simplebar-inverse] .simplebar-scrollbar:before {
    background-color: rgba(255, 255, 255, .35)
}

[data-simplebar-inverse][data-simplebar-auto-hide=false] .simplebar-track {
    background-color: rgba(255, 255, 255, .2)
}

[data-simplebar-horizontal-top] .simplebar-track.simplebar-horizontal {
    top: 0
}

.accordion-button {
    font-weight: bold
}

.accordion-button:not(.collapsed) {
    box-shadow: none
}

.accordion-body {
    padding-top: .1667rem
}

.accordion-light .accordion-item {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .15)
}

.accordion-light .accordion-button {
    background-color: transparent !important
}

.accordion-light .accordion-button.collapsed {
    color: rgba(255, 255, 255, .7)
}

.accordion-light .accordion-button:not(.collapsed) {
    color: #fff;
    box-shadow: none
}

.accordion-light .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.accordion-light .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.7%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.collapse-label::after {
    content: attr(data-bs-label-expanded)
}

.collapse-label>[class^=fi-], .collapse-label>[class*=" fi-"] {
    transform: rotate(180deg);
    transition: transform .2s ease-in-out
}

.collapse-label.collapsed>[class^=fi-], .collapse-label.collapsed>[class*=" fi-"] {
    transform: none
}

.collapse-label.collapsed::after {
    content: attr(data-bs-label-collapsed)
}

.alert [class^=fi-], .alert [class*=" fi-"] {
    margin-top: .175rem
}

.alert hr {
    color: rgba(0, 0, 0, .1)
}

.alert-secondary {
    border-color: #d5d2dc;
    background-color: #f5f4f8
}

.alert-secondary, .alert-light {
    color: #454056
}

.alert-secondary .alert-link, .alert-light .alert-link {
    color: #1f1b2d
}

.alert-dark {
    border-color: #1f1b2d;
    background-color: #1f1b2d;
    color: #fff
}

.alert-dark .alert-link {
    color: #fff
}

.alert-dismissible .btn-close {
    padding-top: 1.5rem
}

.card-header-tabs, .card-header-pills {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0
}

.card-hover {
    transition: border-color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out !important
}

.card-hover:hover, .card-hover.show {
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.card-hover.shadow-sm:hover, .card-hover.shadow-sm.show {
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12) !important
}

.card-hover[class^=bg-]:hover, .card-hover[class*=" bg-"]:hover, .card-hover[class*=" bg-"].active, .card-hover[class*=" bg-"].show {
    background-color: #fff !important
}

.card-active {
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.card-active[class^=bg-], .card-active[class*=" bg-"] {
    background-color: #fff !important
}

.card-img-hover {
    position: relative;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black)
}

.card-img-hover .img-overlay {
    opacity: 0 !important
}

.card-img-hover .content-overlay {
    position: absolute;
    transition: opacity .25s ease-in-out;
    opacity: 0
}

.card-img-hover .position-absolute {
    z-index: 5
}



.card:hover>.card-img-hover .img-overlay {
    opacity: .33 !important
}

.card:hover>.card-img-hover .img-overlay.opacity-25 {
    opacity: .25 !important
}

.card:hover>.card-img-hover .img-overlay.opacity-50 {
    opacity: .5 !important
}

.card:hover>.card-img-hover .slick-arrow {
    opacity: 1;
    visibility: visible;
}

.card:hover>.card-img-hover .img-overlay.opacity-60 {
    opacity: .6 !important
}

.card:hover>.card-img-hover .img-overlay.opacity-65 {
    opacity: .65 !important
}

.card:hover>.card-img-hover .img-overlay.opacity-70 {
    opacity: .7 !important
}

.card:hover>.card-img-hover .content-overlay {
    opacity: 1
}

.card-horizontal .card-img-top, .card-horizontal .card-img-bottom {
    min-height: 12rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

@media(min-width: 500px) {
    .card-horizontal {
        flex-direction: row
    }

    .card-horizontal .card-body {
        padding: 1.25rem 1.5625rem
    }

    .card-horizontal .card-img-top, .card-horizontal .card-img-bottom {
        max-width: 39%;
        min-width: 39%
    }

    .card-horizontal .card-img-top {
        border-radius: 0;
        border-top-left-radius: calc(0.75rem - 1px);
        border-bottom-left-radius: calc(0.75rem - 1px)
    }

    .card-horizontal .card-img-top.order-sm-2 {
        border-radius: 0;
        border-top-right-radius: calc(0.75rem - 1px);
        border-bottom-right-radius: calc(0.75rem - 1px)
    }
}

.card-light {
    border-color: transparent;
    background-color: rgba(255, 255, 255, .04);
    color: #fff
}

.card-light .card-title {
    color: #fff
}

.card-light .card-header, .card-light .card-footer {
    border-color: rgba(255, 255, 255, .15)
}

.card-light.bg-transparent.card-hover:hover, .card-light.bg-transparent.card-active {
    background-color: rgba(255, 255, 255, .04) !important
}

.card-light.card-hover:hover, .card-light.card-active {
    border-color: rgba(255, 255, 255, .15)
}

.card-light.card-hover:hover.border-light, .card-light.card-active.border-light {
    border-color: rgba(255, 255, 255, .4) !important
}

a.card {
    text-decoration: none
}

.card[data-bs-toggle=collapse] {
    transition: border-color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out;
    cursor: pointer
}

.card[data-bs-toggle=collapse] .card-title {
    transition: color .25s ease-in-out
}

.card[data-bs-toggle=collapse].collapsed .card-title {
    color: #454056
}

.card[data-bs-toggle=collapse]:not(.collapsed) {
    background-color: #fff !important;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.card[data-bs-toggle=collapse]:not(.collapsed) .card-title {
    color: #3b82f6
}

.card>.blockquote {
    margin-top: -3.434rem;
    margin-bottom: 0;
    color: #666276;
    font-size: 1rem;
    font-weight: normal
}

.card>.blockquote::before {
    display: table;
    margin-bottom: -0.375rem;
    margin-left: auto
}

.badge.bg-secondary, .badge.bg-light {
    color: #1f1b2d
}

.badge.bg-warning {
    color: #fff
}

.badge.bg-faded-primary {
    color: #3b82f6
}

.badge.bg-faded-accent {
    color: #5d3cf2
}

.badge.bg-faded-secondary {
    color: #f5f4f8
}

.badge.bg-faded-success {
    color: #07c98b
}

.badge.bg-faded-info {
    color: #3c76f2
}

.badge.bg-faded-warning {
    color: #fdbc31
}

.badge.bg-faded-danger {
    color: #f23c49
}

.badge.bg-faded-light {
    color: #fff
}

.badge.bg-faded-dark {
    color: #1f1b2d
}

.badge.bg-faded-secondary {
    color: #1f1b2d
}

.badge.bg-faded-light {
    background-color: rgba(255, 255, 255, .1) !important
}

.list-group-item {
    margin-bottom: 0
}

.list-group-item+.list-group-item.active {
    border-top-color: transparent
}

.list-group-item-action {
    transition: color .25s ease-in-out, background-color .25s ease-in-out
}

.list-group-item-action:active {
    transition: none
}

.list-group-item-action.active {
    border-color: transparent
}

.list-group-item-primary, .list-group-item-primary:hover {
    background-color: rgba(253, 86, 49, .1) !important
}

.list-group-item-accent, .list-group-item-accent:hover {
    background-color: rgba(93, 60, 242, .1) !important
}

.list-group-item-success, .list-group-item-success:hover {
    background-color: rgba(7, 201, 139, .1) !important
}

.list-group-item-info, .list-group-item-info:hover {
    background-color: rgba(60, 118, 242, .1) !important
}

.list-group-item-warning, .list-group-item-warning:hover {
    background-color: rgba(253, 188, 49, .15) !important
}

.list-group-item-danger, .list-group-item-danger:hover {
    background-color: rgba(242, 60, 73, .1) !important
}

.list-group-item-dark, .list-group-item-dark:hover {
    background-color: rgba(31, 27, 45, .1) !important
}

.list-group-item-secondary, .list-group-item-light {
    color: #666276 !important
}

.list-group-item-light:hover {
    background-color: #fff !important
}

.list-group-item-secondary {
    background-color: #f5f4f8 !important
}

.list-group-item-secondary:hover {
    background-color: #f5f4f8 !important
}

.list-group-item-dark, .list-group-item-dark:hover {
    background-color: #1f1b2d !important;
    color: #fff !important
}

.input-group .position-absolute {
    z-index: 5
}

.input-group .position-absolute+.form-control, .input-group .position-absolute+.password-toggle .form-control {
    padding-left: 2.5rem
}

.input-group .form-check-input {
    margin-top: .5rem
}

.form-group {
    display: flex;
    align-items: center;
    padding: .5rem;
    border: 1px solid #efecf3;
    border-radius: .5rem;
    background-color: #fff;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.form-group>div:first-child:not(.row), .form-group>.form-control:first-child, .form-group>.form-select:first-child {
    margin-left: -0.5rem
}

.form-group .form-control, .form-group .form-select {
    border: 0;
    border-radius: 0;
    background-color: transparent
}

.form-group .form-control:focus, .form-group .form-select:focus {
    box-shadow: none
}

.form-group .input-group .input-group-text {
    margin-top: .125rem;
    padding-right: 0;
    border: 0;
    background: transparent
}

.form-group .input-group .input-group-text+.form-control, .form-group .input-group .input-group-text+.form-select {
    padding-left: .750375rem
}

.form-group .dropdown-toggle {
    font-weight: normal
}

.form-group .dropdown-toggle.btn-link {
    padding-right: 1.092rem;
    padding-left: 1.092rem;
    color: #9691a4
}

.form-group .dropdown-toggle.btn-link:hover, .form-group .dropdown-toggle.btn-link.show {
    color: #454056
}

.form-group .dropdown-menu:not(.my-3) {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important
}

.form-group .range-slider {
    width: 100%;
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.form-group .range-slider-ui {
    margin-top: 0;
    margin-bottom: 0
}

.form-group.rounded-pill, .form-group.rounded-xl-pill, .form-group.rounded-lg-pill, .form-group.rounded-md-pill, .form-group.rounded-sm-pill {
    padding-left: .75rem
}

.form-group-light {
    border-color: rgba(255, 255, 255, .15);
    background-color: rgba(255, 255, 255, .04)
}

.form-group-light .form-control, .form-group-light .form-select {
    color: #fff
}

.form-group-light .form-control:focus, .form-group-light .form-select:focus {
    background-color: transparent
}

.form-group-light .input-group .input-group-text {
    color: rgba(255, 255, 255, .55)
}

.form-group-light .dropdown-toggle.btn-link {
    color: rgba(255, 255, 255, .5)
}

.form-group-light .dropdown-toggle.btn-link:hover, .form-group-light .dropdown-toggle.btn-link.show {
    color: #fff
}

.form-group-light .range-slider .range-slider-ui {
    background-color: rgba(255, 255, 255, .2)
}

.dropdown-toggle::after, .dropdown-toggle::before {
    display: inline-block;
    font-family: "finder-icons";
    font-size: .65em;
    font-weight: normal;
    vertical-align: middle
}

.dropdown-toggle::after {
    margin-left: .5rem;
    content: ""
}

.dropdown-toggle::before {
    margin-right: .25rem
}

.dropdown-toggle:not(.dropdown-toggle-split)::before {
    margin-left: -0.25rem
}

.dropdown-toggle:not(.dropdown-toggle-split)::after {
    margin-right: -0.25rem
}

.dropdown .dropdown-toggle::before, .dropup .dropdown-toggle::before, .dropend .dropdown-toggle::before {
    display: none
}

.dropup .dropdown-toggle::after {
    content: ""
}

.dropend .dropdown-toggle::after {
    content: ""
}

.dropstart .dropdown-toggle::before {
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

h1 .dropdown-toggle::after, h2 .dropdown-toggle::after, h3 .dropdown-toggle::after, h4 .dropdown-toggle::after, h5 .dropdown-toggle::after, .h5 .dropdown-toggle::after, h6 .dropdown-toggle::after, .h1 .dropdown-toggle::after, .h2 .dropdown-toggle::after, .h3 .dropdown-toggle::after, .h4 .dropdown-toggle::after, .h6 .dropdown-toggle::after {
    font-size: 1rem
}

.dropdown-menu li {
    margin-bottom: 0
}

.dropdown-menu li:hover>.dropdown-item {
    color: #3b82f6
}

.dropdown-menu .active>.dropdown-item {
    color: #3b82f6
}

.dropdown-menu .active>.dropdown-item, .dropdown-menu .dropdown-item.active {
    pointer-events: none
}

.dropdown-menu.dropdown-menu-dark li:hover>.dropdown-item {
    color: #fff
}

.dropdown-menu.dropdown-menu-dark .active>.dropdown-item {
    color: #fff
}

.dropdown-menu.w-100 {
    min-width: 100%
}

.dropdown-item {
    transition: color .2s ease-in-out
}

.dropdown-item>i {
    margin-top: -0.125rem;
    transition: opacity .25s ease-in-out
}

.dropdown-item:hover>i, .dropdown-item.active>i {
    opacity: 1 !important
}

.dropdown-header {
    border-bottom: 0;
    font-size: 1rem
}

.dropdown-divider {
    margin-right: 1rem;
    margin-left: 1rem
}

.dropdown-menu.show {
    -webkit-animation: fade-in .25s ease-in-out;
    animation: fade-in .25s ease-in-out
}

.dropdown-toggle-split::after {
    margin-left: -0.0625rem !important
}

@-webkit-keyframes fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes slide-up {
    from {
        transform: translateY(0.5rem);
        opacity: 0
    }

    to {
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes slide-up {
    from {
        transform: translateY(0.5rem);
        opacity: 0
    }

    to {
        transform: translateY(0);
        opacity: 1
    }
}

.page-item {
    margin: 0 .25rem
}

.page-item.active>.page-link {
    font-weight: bold;
    border-radius: 50%;
    cursor: default
}

.page-link>i {
    display: inline-block;
    margin-top: -0.125rem;
    vertical-align: middle;
    font-size: .7em
}

.page-link.page-link-static:hover {
    color: #454056
}

.pagination-light .page-item.active .page-link {
    background-color: #fff;
    color: #3b82f6;
    border-color: transparent
}

.pagination-light .page-item.disabled .page-link {
    background-color: transparent;
    color: rgba(255, 255, 255, .3);
    border-color: transparent
}

.pagination-light .page-link {
    background-color: transparent;
    color: rgba(255, 255, 255, .7);
    border-color: transparent
}

.pagination-light .page-link:focus {
    box-shadow: none
}

.pagination-light .page-link:hover {
    background-color: transparent;
    color: #fff;
    border-color: transparent
}

.pagination-light .page-link.page-link-static:hover {
    color: rgba(255, 255, 255, .7)
}

.breadcrumb-item+.breadcrumb-item::before {
    margin-top: .25rem;
    font-family: "finder-icons";
    font-size: .7em
}

.breadcrumb-item>a {
    color: #9691a4;
    text-decoration: none
}

.breadcrumb-item>a:hover {
    color: #454056
}

.breadcrumb-light .breadcrumb-item>a {
    color: rgba(255, 255, 255, .5)
}

.breadcrumb-light .breadcrumb-item>a:hover {
    color: rgba(255, 255, 255, .75)
}

.breadcrumb-light .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-light .breadcrumb-item+.breadcrumb-item::before {
    color: rgba(255, 255, 255, .5)
}

.progress.progress-light {
    background-color: rgba(255, 255, 255, .15)
}

.btn-close {
    transition: opacity .25s ease-in-out
}

.modal-header .btn-close {
    margin: -0.25rem -0.28125rem -0.25rem auto
}

.img-overlay, .img-gradient-overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .25s ease-in-out;
    background-color: #1f1b2d;
    opacity: .5;
    z-index: 1
}

.img-gradient-overlay {
    background: rgba(31, 27, 45, .5);
    background: linear-gradient(180deg, rgba(31, 27, 45, 0) 0%, rgba(31, 27, 45, 0.1) 26.56%, rgba(31, 27, 45, 0.28) 42.71%, rgba(31, 27, 45, 0.48) 56.77%, rgba(31, 27, 45, 0.68) 72.4%, rgba(31, 27, 45, 0.85) 86.98%, rgba(31, 27, 45, 0.94) 100%);
    opacity: 1
}

.content-overlay {
    position: relative;
    z-index: 5
}

.date-picker[readonly] {
    background-color: #fff
}

.date-picker[readonly].form-control-light {
    background-color: rgba(255, 255, 255, .04)
}

.flatpickr-calendar {
    width: 325px;
    padding: 0 .5rem;
    border-radius: .75rem;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08);
    border: 0
}

.flatpickr-innerContainer {
    padding-bottom: 1.125rem
}

.flatpickr-months {
    padding: .75rem 0
}

.flatpickr-months svg {
    vertical-align: top
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
    top: .75rem
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
    fill: #3b82f6
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    padding: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.numInputWrapper:hover, .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background-color: #f6f5f8
}

.flatpickr-day {
    height: 38px;
    border-radius: 0;
    border-radius: .5rem;
    line-height: 37px
}

.flatpickr-day:hover:not(.flatpickr-disabled):not(.today):not(.selected) {
    border-color: #f6f5f8;
    background-color: #f6f5f8
}

.flatpickr-day.today {
    border-color: rgba(253, 86, 49, .5) !important;
    color: #3b82f6 !important
}

.flatpickr-day.today.selected {
    color: #fff !important
}

.flatpickr-day.today:hover {
    background-color: transparent
}

.flatpickr-day.selected {
    border-color: #3b82f6 !important;
    background-color: #3b82f6 !important
}

.flatpickr-day.flatpickr-disabled {
    color: #c5c1cf !important
}

.flatpickr-day.nextMonthDay {
    color: #aea9ba
}

.flatpickr-day.inRange {
    border-color: #f6f5f8 !important;
    background-color: #f6f5f8 !important;
    box-shadow: -5px 0 0 #f6f5f8, 5px 0 0 #f6f5f8
}

.flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.endRange.seleced, .flatpickr-day.endRange:hover {
    border-color: #3b82f6 !important;
    background-color: #3b82f6 !important
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
    border-radius: 0;
    border-radius: 0 .5rem .5rem 0
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
    border-radius: 0;
    border-radius: .5rem 0 0 .5rem
}

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: -10px 0 0 #3b82f6
}

.flatpickr-time {
    margin-bottom: .5rem
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
    background-color: #f6f5f8
}

.flatpickr-time input.flatpickr-hour {
    font-weight: 700
}

.file-uploader {
    margin-bottom: 0;
    border: 1px dashed #d5d2dc;
    border-radius: .75rem;
    font-family: "Noto Sans", sans-serif;
    cursor: pointer
}

.filepond--root .filepond--drop-label {
    min-height: 10rem
}

.filepond--root .filepond--drop-label>label {
    cursor: pointer
}

.filepond--panel, .filepond--panel-root {
    background-color: transparent !important
}

.filepond--image-preview {
    background-color: #1f1b2d !important
}

.filepond--credits {
    display: none
}

@media(min-width: 500px) {
    .file-uploader-grid .filepond--item {
        width: calc(50% - .5em)
    }
}

[data-filepond-item-state*=error] .filepond--item-panel, [data-filepond-item-state*=invalid] .filepond--item-panel {
    background-color: #f23c49
}

[data-filepond-item-state=processing-complete] .filepond--item-panel {
    background-color: #07c98b
}

.tns-carousel-wrapper {
    position: relative
}

.tns-carousel-wrapper .tns-carousel-inner {
    position: relative;
    display: flex;
    overflow: hidden;
    touch-action: manipulation;
    
}

.tns-carousel-wrapper .tns-carousel-inner:active {
    cursor: -webkit-grab;
    cursor: grab
}

.tns-carousel-wrapper .tns-carousel-inner.tns-slider {
    display: block;
    opacity: 1
}

.tns-carousel-wrapper .tns-carousel-inner>* {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.tns-carousel-wrapper .tns-carousel-inner.tns-horizontal:not(.tns-gallery) {
    display: flex
}

.tns-carousel-wrapper .tns-carousel-inner.position-absolute {
    z-index: 0
}

.card-img-top .tns-horizontal {
    align-items: flex-start
}

@supports(-ms-ime-align: auto) {
    .tns-carousel-wrapper .tns-carousel-inner.tns-horizontal:not(.tns-gallery) {
        display: block
    }
}

.tns-carousel-wrapper .tns-controls:focus {
    outline: none
}

.tns-carousel-wrapper [data-controls] {
    position: absolute;
    top: 50%;
    width: 2.25rem;
    height: 2.25rem;
    margin-top: -1.125rem;
    transition: all .3s ease-in-out;
    border-radius: 50%;
    background-color: #fff;
    color: #454056;
    font-size: .75rem;
    line-height: 2.25rem -0.25rem;
    text-align: center;
    border: 0;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08);
    z-index: 10
}

.tns-carousel-wrapper [data-controls]:hover {
    color: #3b82f6;
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12)
}

.tns-carousel-wrapper [data-controls][disabled] {
    opacity: 0 !important;
    visibility: hidden
}

.tns-carousel-wrapper.tns-carousel-light [data-controls] {
    background-color: rgba(255, 255, 255, .04);
    color: #fff;
    box-shadow: none
}

.tns-carousel-wrapper.tns-carousel-light [data-controls]:hover {
    background-color: #fff;
    color: #3b82f6
}

.tns-carousel-wrapper [data-controls=prev] {
    left: 1rem
}

.tns-carousel-wrapper [data-controls=prev]>i:not(.fi-chevron-up) {
    margin-right: .0625rem
}

.tns-carousel-wrapper [data-controls=prev]>i.fi-chevron-up {
    margin-bottom: .125rem
}

.tns-carousel-wrapper [data-controls=next] {
    right: 1rem
}

.tns-carousel-wrapper [data-controls=next]>i:not(.fi-chevron-down) {
    margin-left: .0625rem
}

.tns-carousel-wrapper:not(.tns-controls-static):not([class^=tns-controls-outside]):not([class*=" tns-controls-outside"]) [data-controls] {
    opacity: 0
}

.tns-carousel-wrapper:not(.tns-controls-static):not([class^=tns-controls-outside]):not([class*=" tns-controls-outside"]) [data-controls=prev] {
    transform: translateX(1rem)
}

.tns-carousel-wrapper:not(.tns-controls-static):not([class^=tns-controls-outside]):not([class*=" tns-controls-outside"]) [data-controls=next] {
    transform: translateX(-1rem)
}

.tns-carousel-wrapper:not(.tns-controls-static):not([class^=tns-controls-outside]):not([class*=" tns-controls-outside"]):hover [data-controls] {
    transform: none;
    opacity: 1
}

.tns-carousel-wrapper.tns-nav-outside [data-controls] {
    margin-top: -2.9rem
}

.tns-carousel-controls {
    display: flex
}

.tns-carousel-controls button {
    display: none
}

.tns-carousel-controls:focus {
    outline: none
}

.tns-carousel-controls [data-controls] {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    transition: all .3s ease-in-out;
    border-radius: 50%;
    background-color: #fff;
    color: #454056;
    font-size: .75rem;
    line-height: 2.25rem -0.25rem;
    text-align: center;
    z-index: 10;
    border: 0;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.tns-carousel-controls [data-controls]:hover {
    color: #3b82f6;
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12)
}

.tns-carousel-controls [data-controls][disabled] {
    opacity: 0 !important;
    visibility: hidden
}

.tns-carousel-controls [data-controls=prev]>i:not(.fi-chevron-up) {
    margin-right: .0625rem
}

.tns-carousel-controls [data-controls=prev]>i.fi-chevron-up {
    margin-bottom: .125rem
}

.tns-carousel-controls [data-controls=next]>i:not(.fi-chevron-down) {
    margin-left: .0625rem
}

.tns-carousel-controls.tns-carousel-light [data-controls] {
    background-color: rgba(255, 255, 255, .04);
    color: #fff;
    box-shadow: none
}

.tns-carousel-controls.tns-carousel-light [data-controls]:hover {
    background-color: #fff;
    color: #3b82f6
}

.tns-carousel-wrapper:not(.tns-nav-outside) .tns-nav {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 1rem;
    text-align: center
}

.tns-carousel-wrapper [data-nav] {
    display: inline-block;
    width: 1rem;
    height: .375rem;
    margin: 0 .3rem;
    padding: 0;
    border: 0;
    border-radius: 50rem;
    background-color: rgba(255, 255, 255, .4)
}

.tns-carousel-wrapper [data-nav]:focus {
    outline: none
}




/* Styling for all dots */


.slider2 .slick-dots li button::before {
    width: 17px !important;
    height: 6px !important;
    background: black;
    border-radius: 14px;
    display: inline-block;
    content: ""!important;
  }

 
  .categoryslider .slick-dots li button::before {
  
    content: none;
  }

 
  


.slider2 .slick-dots li button {
    margin-top: 25px;
}

.slider2 .slick-dots{
    display:flex !important ;
    flex-direction:row !important ;
    justify-content:center !important;
    align-content:center !important;
  


}

/* Additional margin around dots */


/* Styling for the active dot */
  .slider2 .slick-dots li.slick-active button:before {
    background: black;
    border-radius: 4px !important;
    width: 25px !important;
    transition: width 0.5s ease, background 0.5s ease !important; 
    text-align: center;


}
.slider3 .slick-dots{
    margin-top: -800px !important;
    z-index:100000;
    background-color: transparent;
   
    height: 50px;
    color: white !important;
    

}


.slider3 .slick-dots li button::before{
    color: white !important;
 
    

}

/* Styling for the active dot */
.slider2 .slick-dots li.slick-active button {
  width:100%;

}






.slider2 .slick-dots li  {
 
  margin: 50px 0px 0px;
 
  width: 30px;
display:flex !important;

justify-content:center !important;
align-items:center !important;



}



.tns-carousel-wrapper [data-nav].tns-nav-active {
    width: 2rem;
    background-color: #fff
}


.tns-carousel-wrapper.tns-nav-outside .tns-nav {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    text-align: center
}

.tns-carousel-wrapper.tns-nav-outside:not(.tns-carousel-light) [data-nav] {
    background-color: rgba(102, 98, 118, .4)
}

.tns-carousel-wrapper.tns-nav-outside:not(.tns-carousel-light) [data-nav].tns-nav-active {
    background-color: #666276
}

.tns-carousel-wrapper.tns-nav-outside-flush .tns-nav {
    padding: 0
}

.tns-controls-outside-sm .tns-controls {
    display: none
}

@media(min-width: 500px) {
    .tns-controls-outside-sm .tns-controls {
        display: block
    }

    .tns-controls-outside-sm [data-controls=prev] {
        left: -3rem
    }

    .tns-controls-outside-sm [data-controls=next] {
        right: -3rem
    }
}

.tns-controls-outside-md .tns-controls {
    display: none
}

@media(min-width: 768px) {
    .tns-controls-outside-md .tns-controls {
        display: block
    }

    .tns-controls-outside-md [data-controls=prev] {
        left: -3rem
    }

    .tns-controls-outside-md [data-controls=next] {
        right: -3rem
    }
}

.tns-controls-outside-lg .tns-controls {
    display: none
}

@media(min-width: 992px) {
    .tns-controls-outside-lg .tns-controls {
        display: block
    }

    .tns-controls-outside-lg [data-controls=prev] {
        left: -3rem
    }

    .tns-controls-outside-lg [data-controls=next] {
        right: -3rem
    }
}

.tns-controls-outside-xl .tns-controls {
    display: none
}

@media(min-width: 1200px) {
    .tns-controls-outside-xl .tns-controls {
        display: block
    }

    .tns-controls-outside-xl [data-controls=prev] {
        left: -3rem
    }

    .tns-controls-outside-xl [data-controls=next] {
        right: -3rem
    }
}

.tns-controls-outside-xxl .tns-controls {
    display: none
}

@media(min-width: 1400px) {
    .tns-controls-outside-xxl .tns-controls {
        display: block
    }

    .tns-controls-outside-xxl [data-controls=prev] {
        left: -3rem
    }

    .tns-controls-outside-xxl [data-controls=next] {
        right: -3rem
    }
}

.tns-controls-outside .tns-controls {
    display: none
}

.tns-controls-outside .tns-controls {
    display: block
}

.tns-controls-outside [data-controls=prev] {
    left: -3rem
}

.tns-controls-outside [data-controls=next] {
    right: -3rem
}

.tns-slides-count {
    position: absolute;
    display: flex;
    align-items: center;
    right: 1.5rem;
    bottom: 1.25rem;
    z-index: 10
}

.tns-thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.4375rem;
    margin-left: -0.4375rem;
    padding: .4375rem 0;
    list-style: none
}

.tns-thumbnails:focus {
    outline: none
}

.tns-thumbnail {
    display: block;
    width: 20%;
    margin: 0;
    padding: .4375rem;
    cursor: pointer
}

.tns-thumbnail:focus {
    outline: none
}

.tns-thumbnail>img {
    display: block;
    width: 100%;
    transition: opacity .2s ease-in-out, border-color .2s ease-in-out;
    border: 1px solid transparent;
    border-radius: .5rem;
    opacity: .6
}

.tns-thumbnail:hover>img {
    opacity: 1
}

.tns-thumbnail.tns-nav-active {
    pointer-events: none;
    cursor: default
}

.tns-thumbnail.tns-nav-active>img {
    border-color: #3b82f6;
    opacity: 1
}

@media(max-width: 499.98px) {
    .tns-thumbnails {
        margin-right: -0.291375rem;
        margin-left: -0.291375rem;
        padding: .291375rem 0
    }

    .tns-thumbnail {
        width: 33.33%;
        padding: .291375rem
    }
}

.card>.card-img-hover .tns-nav {
    display: none
}

.card>.card-img-hover [data-controls] {
    transform: none !important;
    background: none;
    color: #fff;
    font-size: 1.03125rem;
    box-shadow: none
}

.card>.card-img-hover [data-controls=prev] {
    left: .25rem
}

.card>.card-img-hover [data-controls=next] {
    right: .25rem
}

.card:hover>.card-img-hover [data-controls] {
    opacity: 1 !important
}

.tns-center .tns-inner {
    margin-right: -1rem !important
}

@media(min-width: 768px) {
    .tns-center .tns-inner {
        margin: 0 15% 0 16.5% !important
    }

    .tns-center .tns-item {
        transition: opacity .15s ease-in-out;
        opacity: .6
    }

    .tns-center .tns-item.tns-slide-active.active:not(.tns-slide-cloned) {
        opacity: 1
    }
}

.tns-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.tns-item .from-top, .tns-item .from-bottom, .tns-item .from-start, .tns-item .from-end, .tns-item .scale-up, .tns-item .scale-down {
    transition: all .45s .3s ease-in-out;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.tns-item .from-top {
    transform: translateY(-45px)
}

.tns-item .from-bottom {
    transform: translateY(45px)
}

.tns-item .from-start {
    transform: translateX(-45px)
}

.tns-item .from-end {
    transform: translateX(45px)
}

.tns-item .scale-up {
    transform: scale(0.8)
}

.tns-item .scale-down {
    transform: scale(1.2)
}

.tns-item .delay-1 {
    transition-delay: .5s
}

.tns-item .delay-2 {
    transition-delay: .7s
}

.tns-item .delay-3 {
    transition-delay: .9s
}

.tns-item .delay-4 {
    transition-delay: 1.1s
}

.tns-item.tns-slide-active .from-top, .tns-item.tns-slide-active .from-bottom {
    transform: translateY(0);
    opacity: 1
}

.tns-item.tns-slide-active .from-start, .tns-item.tns-slide-active .from-end {
    transform: translateX(0);
    opacity: 1
}

.tns-item.tns-slide-active .scale-up, .tns-item.tns-slide-active .scale-down {
    transform: scale(1);
    opacity: 1
}

.gallery-item {
    display: block;
    position: relative;
    text-decoration: none !important;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black)
}

.gallery-item {
    display: block;
    position: relative;
    text-decoration: none !important;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black)
}

.gallery-item>img {
    display: block;
    width: 100%
}

.gallery-item::before {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease-in-out;
    background-color: rgba(31, 27, 45, .5);
    content: "";
    opacity: 0;
    z-index: 1
}

.gallery-item::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.875rem;
    height: 2.875rem;
    margin-top: -1.4375rem;
    margin-left: -1.4375rem;
    transform: scale(0.3);
    color: #fff;
    font-family: "finder-icons";
    font-size: 1.75rem;
    text-align: center;
    line-height: 2.875rem;
    content: "";
    z-index: 5
}

.gallery-item .gallery-item-caption {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem .5rem;
    transform: translateY(0.5rem);
    color: #fff;
    font-size: .875rem;
    text-align: center;
    z-index: 5
}

.gallery-item::after, .gallery-item .gallery-item-caption {
    transition: all .3s ease-in-out;
    opacity: 0
}

.gallery-item:hover::before, .gallery-item:hover::after, .gallery-item:hover .gallery-item-caption {
    transform: none;
    opacity: 1
}

.gallery-item.video-item::after {
    padding-top: .0625rem;
    padding-left: .0625rem;
    transform: none;
    border-radius: 50%;
    background-color: #fff;
    color: #454056;
    font-size: .5625rem;
    opacity: 1;
    box-shadow: 0 .375rem 1rem -0.25rem rgba(0, 0, 0, .2);
    content: ""
}

.gallery-item.more-item::before {
    background: rgba(31, 27, 45, .6);
    opacity: 1
}

.gallery-item.more-item::after {
    display: none
}

.gallery-item.more-item .gallery-item-caption {
    top: 50%;
    bottom: auto;
    font-weight: bold;
    text-decoration: underline;
    transform: translateY(-50%);
    opacity: 1
}

.gallery-item.more-item:hover .gallery-item-caption {
    text-decoration: none
}
.lg-backdrop {
    z-index: 1100
}

.lg-outer {
    z-index: 1110
}

.lg-outer .lg-thumb-item:hover, .lg-outer .lg-thumb-item.active {
    border-color: #3b82f6
}

.lg-on {
    overflow: hidden
}

.lg-item:focus, .lg:focus, .lg-outer:focus, .lg-inner:focus {
    outline: none
}

.icon-box-media {
    display: block;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    transition: background-color .22s ease-in-out, color .22s ease-in-out;
    border-radius: .5rem;
    background-position: center;
    background-size: cover;
    font-size: 1.5rem;
    line-height: 2.75rem;
    text-align: center
}

.icon-box-title {
    margin-bottom: .5rem
}

.icon-box-title:not(.text-light):not(.text-white) {
    color: #1f1b2d !important
}

.icon-box:hover .icon-box-media.text-primary, .icon-box.active .icon-box-media.text-primary, .icon-box.show .icon-box-media.text-primary {
    background-color: #3b82f6 !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-accent, .icon-box.active .icon-box-media.text-accent, .icon-box.show .icon-box-media.text-accent {
    background-color: #5d3cf2 !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-success, .icon-box.active .icon-box-media.text-success, .icon-box.show .icon-box-media.text-success {
    background-color: #07c98b !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-info, .icon-box.active .icon-box-media.text-info, .icon-box.show .icon-box-media.text-info {
    background-color: #3c76f2 !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-warning, .icon-box.active .icon-box-media.text-warning, .icon-box.show .icon-box-media.text-warning {
    background-color: #fdbc31 !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-danger, .icon-box.active .icon-box-media.text-danger, .icon-box.show .icon-box-media.text-danger {
    background-color: #f23c49 !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-dark, .icon-box.active .icon-box-media.text-dark, .icon-box.show .icon-box-media.text-dark {
    background-color: #1f1b2d !important;
    color: #fff !important
}

.icon-box:hover .icon-box-media.text-light, .icon-box.active .icon-box-media.text-light, .icon-box.show .icon-box-media.text-light {
    background-color: #fff !important;
    color: #3b82f6 !important
}

.star-rating {
    display: inline-block;
    white-space: nowrap
}

.star-rating-icon {
    display: inline-block;
    margin-right: .125rem;
    color: #bbb7c5;
    font-size: .875rem
}

.star-rating-icon.active {
    color: #fdbc31
}

.text-light .star-rating-icon, .text-white .star-rating-icon {
    color: rgba(255, 255, 255, .5)
}

.text-light .star-rating-icon.active, .text-white .star-rating-icon.active {
    color: #fdbc31
}

.star-rating-sm .star-rating-icon {
    font-size: .6875rem
}

.steps {
    display: flex;
    padding-top: 1rem
}

.step {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    text-decoration: none
}

.step-progress {
    position: relative;
    width: 100%;
    height: .25rem
}

.step-progress-start, .step-progress-end {
    position: absolute;
    top: 50%;
    width: 50%;
    height: .125rem;
    margin-top: -0.0625rem;
    overflow: hidden
}

.step-progress-start::before, .step-progress-end::before {
    display: block;
    width: 62rem;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23b6b1c0' stroke-width='4' stroke-dasharray='2%2c 18' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
    content: ""
}

.step-progress-start {
    left: 0
}

.step-progress-end {
    left: 50%
}

.step:first-child .step-progress .step-progress-start {
    display: none
}

.step:last-child .step-progress .step-progress-end {
    display: none
}

.step-number {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    margin-left: -1rem;
    transition: background-color .25s ease-in-out, color .2s ease-in-out, box-shadow .2s ease-in-out;
    border-radius: 50%;
    background: #fff;
    color: #666276;
    font-size: 1rem;
    font-weight: 700;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08);
    line-height: 2rem
}

.step-label {
    padding-top: 1.5rem;
    color: #666276;
    font-size: .75rem
}

.step.active .step-progress .step-progress-start, .step.active .step-progress .step-progress-end {
    height: .25rem;
    background-color: #3b82f6
}

.step.active .step-progress .step-progress-start::before, .step.active .step-progress .step-progress-end::before {
    display: none
}

.step.active .step-number {
    background-color: #3b82f6;
    color: #fff;
    box-shadow: 0 .25rem .75rem -0.375rem rgba(253, 86, 49, .2), 0 .5rem 1rem rgba(253, 86, 49, .16)
}

.steps.steps-vertical {
    flex-direction: column
}

.steps.steps-vertical .step {
    display: flex
}

.steps.steps-vertical .step.active .step-progress .step-progress-end {
    width: .25rem;
    height: 100%
}

.steps.steps-vertical .step:last-child .step-label {
    margin-bottom: initial
}

.steps.steps-vertical .step-progress {
    flex-shrink: 0;
    width: 2rem;
    height: auto
}

.steps.steps-vertical .step-progress-start {
    display: none
}

.steps.steps-vertical .step-progress-end {
    position: absolute;
    top: 0;
    left: 50%;
    width: .125rem;
    height: 100%;
    transform: translateX(-50%)
}

.steps.steps-vertical .step-progress-end::before {
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23b6b1c0' stroke-width='4' stroke-dasharray='2%2c 12' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
    background-size: 25px
}

.steps.steps-vertical .step-number {
    position: relative;
    top: initial;
    left: initial;
    margin-top: -0.125rem;
    margin-left: initial
}

.steps.steps-vertical .step-label {
    margin-top: -0.125rem;
    margin-bottom: 2.5rem;
    margin-left: 2.5rem;
    padding-top: initial;
    font-size: 1rem;
    text-align: left
}

@media(max-width: 767.98px) {
    .steps.steps-vertical .step-label {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem
    }
}

.steps.steps-vertical.steps-light .step:not(.active) .step-progress-end::before {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='4' stroke-dasharray='2%2c 12' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e")
}

.steps.steps-vertical.steps-light .step:not(.active) .step-number.bg-primary {
    box-shadow: 0 .25rem .75rem -0.375rem rgba(253, 86, 49, .2), 0 .5rem 1rem rgba(253, 86, 49, .16)
}

.steps-light .step:not(.active) .step-progress-start::before, .steps-light .step:not(.active) .step-progress-end::before {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='4' stroke-dasharray='2%2c 18' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e")
}

.steps-light .step:not(.active) .step-number {
    background-color: #353142;
    color: #fff;
    box-shadow: none
}

.steps-light .step.active .step-number {
    box-shadow: none
}

.steps-light .step-label {
    color: rgba(255, 255, 255, .7)
}

a.step:hover .step-number:not(.active) {
    background-color: #3b82f6;
    color: #fff;
    box-shadow: 0 .25rem .75rem -0.375rem rgba(253, 86, 49, .2), 0 .5rem 1rem rgba(253, 86, 49, .16)
}

a.step.active {
    pointer-events: none
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: 0;
    box-shadow: 0 .125rem .5rem -0.25rem rgba(31, 27, 45, .12), 0 .25rem 1rem rgba(31, 27, 45, .12)
}

.leaflet-touch .leaflet-bar a {
    transition: color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out, border-color .25s ease-in-out;
    color: #454056;
    font-weight: normal;
    line-height: 26px
}

.leaflet-touch .leaflet-bar a:first-child {
    border-bottom-color: #efecf3;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.leaflet-touch .leaflet-bar a:hover {
    background-color: #fff;
    color: #3b82f6
}

.leaflet-touch .leaflet-bar a.leaflet-disabled {
    background-color: #fff;
    color: rgba(150, 145, 164, .7)
}

.custom-marker-dot {
    display: block;
    width: .875rem !important;
    height: .875rem !important;
    margin-top: -0.75rem !important;
    margin-left: -0.375rem !important;
    padding-left: .875rem;
    transition: box-shadow .2s ease-in-out;
    border-radius: 50%;
    box-sizing: border-box;
    background: #3b82f6
}

.custom-marker-dot:hover {
    box-shadow: 0 0 0 .625rem rgba(253, 86, 49, .25)
}

.custom-marker-dot.leaflet-marker-shadow {
    display: none
}

.custom-marker-icon {
    display: block;
    width: 3rem !important;
    height: auto !important;
    margin-top: -1.5rem !important;
    margin-left: -1.5rem !important
}

.custom-marker-icon.leaflet-marker-shadow {
    display: none
}

.leaflet-popup-content-wrapper {
    min-width: 17.5rem;
    padding: 0;
    overflow: hidden
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
    width: 100% !important;
    margin: 0 !important;
    color: #666276
}

.leaflet-container {
    font-family: "Noto Sans", sans-serif
}

.leaflet-container a.leaflet-popup-close-button {
    top: .5rem;
    right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    background-color: #fff;
    border-radius: 50%;
    color: rgba(69, 64, 86, .7);
    font-size: .875rem;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: 0 .125rem .125rem -0.125rem rgba(31, 27, 45, .08), 0 .25rem .75rem rgba(31, 27, 45, .08)
}

.leaflet-container a.leaflet-popup-close-button:hover {
    color: #454056
}

.leaflet-popup:focus, .leaflet-marker-icon:focus {
    outline: none
}

.leaflet-popup-tip-container {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

@-moz-document url-prefix() {
    .leaflet-container a.leaflet-popup-close-button {
        line-height: 1.375rem
    }
}

.map-popup {
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    background-color: #fff;
    z-index: 10
}

.map-popup.invisible {
    opacity: 0;
    visibility: hidden
}

.map-popup .btn-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 999
}

.map-popup, .map-popup .interactive-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

@media(max-width: 991.98px) {
    .map-popup {
        position: fixed;
        z-index: 1031
    }
}

.jarallax {
    position: relative;
    z-index: 0
}

.jarallax .jarallax-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-position: center !important;
    background-size: cover !important
}

_:-ms-lang(x), .jarallax .jarallax-img {
    margin: 0 !important;
    position: absolute !important;
    transform: none !important
}

.parallax {
    position: relative
}

.parallax-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.parallax-layer>img {
    display: block;
    width: 100%
}

.swap-image {
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.swap-image .swap-from, .swap-image .swap-to {
    display: block;
    transition: opacity .3s ease-in-out
}

.swap-image .swap-to {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0
}

.swap-image:hover .swap-from, .swap-image.active .swap-from {
    opacity: 0
}

.swap-image:hover .swap-to, .swap-image.active .swap-to {
    opacity: 1
}

.card:hover>.swap-image .swap-from, .card:hover>.swap-image .swap-to, .card.card-active>.swap-image .swap-from, .card.card-active>.swap-image .swap-to {
    transition-duration: .25s
}

.card:hover>.swap-image .swap-from, .card.card-active>.swap-image .swap-from {
    opacity: 0
}

.card:hover>.swap-image .swap-to, .card.card-active>.swap-image .swap-to {
    opacity: 1
}

/*# sourceMappingURL=theme.min.css.map */

span:has(> .main-picture) {
    flex: 0 0 auto;
    width: 66.66666667%
}


.card-left{
    width: 40%;
    height:100%

}

@media (max-width: 500px) {
    .card-left{
        width: 100%;
        height:50%
    
    } 
}


.dropzone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10.5rem;
    border: 2px dashed #d1d5db;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: #f9fafb;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .dropzone:hover {
    background-color: #f3f4f6;
    border-color: #a1a1aa;
  }
  
  .dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
  }
  
  .dropzone-icon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
    color: #6b7280;
  }
  
  .dropzone-text {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    color: #6b7280;
  }
  
  .dropzone-text-bold {
    font-weight: 600;
  }
  
  .dropzone-subtext {
    font-size: 0.75rem;
    color: #6b7280;
  }
  
  .dropzone-input {
    display: none;
  }
  
  .file-info {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #374151;
  }

.file-uploader {
    height: 160px;
    overflow: hidden; /* Ensures the content does not exceed the container */
  }
  
  .filepond--item-panel {
    height: 100%; /* Make sure the panel takes full height of the container */
  }
  
  .filepond--panel-root {
    height: 100%;
  }
  
  .filepond--image-preview-wrapper,
  .filepond--image-preview-wrapper img {
    height: 100%;
    width: auto; /* Ensure the image maintains its aspect ratio */
    object-fit: cover; /* Cover the container while maintaining aspect ratio */
  }
  
  .flatpickr-next-month{
    margin-top:10px !important;
  }

  .flatpickr-prev-month{
    margin-top:10px !important;
  }

  .range .flatpickr-day .flatpickr-disabled{
    background-color: black !important;
  }

  .range{
    display: none !important;
  }

  .flatpickr-disabled {
    position: relative;
  }
  
  .rdrDayDisabled::after {
    content: "X";
    position: absolute;
    right: 15px;
    top: 0px;
    font-size: 20px;
   color: dimgray;
  }
  



  @media (min-width:500px) {

    .newdrpdown{
        margin-left: -160px;
    }
    
  }



    .rec-dot{
        width: 15px !important;
        height: 5px !important;
        background: #8b8b8c !important;
        border-radius: 14px;
        display: inline-block;
        content: ""!important;
        border-radius: 10px !important;
        outline: none !important;
        border-color:none !important;

        box-shadow: none !important;
        border: none !important;
  }

  .rec-dot_active{
    width: 18px !important;
    height: 5px !important;
    background: black !important;
    border-radius: 14px;
    display: inline-block;
    content: ""!important;
    border-radius: 10px !important;
    outline: none !important;
    border-color:none !important;
    border-width: 0 !important;

}

 
  .categoryslider .slick-dots li button::before {
  
    content: none;
  }

 
  


.slider2 .slick-dots li button {
    margin-top: 25px;
}

.slider2 .slick-dots{
    display:flex !important ;
    flex-direction:row !important ;
    justify-content:center !important;
    align-content:center !important;
  


}

/* Additional margin around dots */


/* Styling for the active dot */
  .slider2 .slick-dots li.slick-active button:before {
    background: black !important;
    border-radius: 4px !important;
    width: 25px !important;
    transition: width 0.5s ease, background 0.5s ease !important; 
    text-align: center;


}
.slider3 .slick-dots{
    margin-top: -800px !important;
    z-index:100000;
    background-color: transparent;
   
    height: 50px;
    color: white !important;
    

}


.slider3 .slick-dots li button::before{
    color: white !important;
 
    

}

/* Styling for the active dot */
.slider2 .slick-dots li.slick-active button {
  width:100%;

}






.slider2 .slick-dots li  {
 
  margin: 50px 0px 0px;
 
  width: 30px;
display:flex !important;

justify-content:center !important;
align-items:center !important;



}




.calendar-container {
  max-width: 100%;
  overflow-x: auto; /* Ensure the content is scrollable horizontally */
}

@media (max-width: 1128px) {
  .calendar-container {
    overflow-x: hidden; /* Hide overflow on smaller screens */
  }

  .rdrCalendarWrapper {
    display: block; /* Make the calendar stack vertically */
  }

  .rdrMonths {
    flex-direction: column !important; /* Change direction for smaller screens */
  }
  
  /* Reduce the number of months on smaller screens */
  .calendar-container .rdrCalendarWrapper {
    width: 100% !important; /* Make sure the width adjusts properly */
  }
}
